import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessId,
  paymentStatusForTransactions,
  transactionTypeForTransactions,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import getBase64 from "../../base64"
import { csvDownloadData, getTimestamp, usersData } from "constants/common"

const Transactions = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [userInvestment, setUserInvestment] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [transactionType, setTransactionType] = useState("")
  const [file, setFile] = useState(null)
  const [upload, setUpload] = useState(false)
  const [base64, setBase64] = useState("")
  const [errorModal, setErrorModal] = useState(false)
  const [errorData, setErrorData] = useState([])
  const [errorMsg, setErrorMsg] = useState("")

  const [investmentList, setInvestmentList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [transactionTypeFilter, setTransactionTypeFilter] = useState({})
  const [paymentStatusFilter, setPaymentStatusFilter] = useState({})
  const [userFilter, setUserFilter] = useState("")

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [selectedInvestment, setSelectedInvestment] = useState({})
  const [total, setTotal] = useState(0)
  const [searchValue, setSearchValue] = useState("")

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${
    transactionTypeFilter?.id
      ? `&$or[0][transaction_type]=${transactionTypeFilter?.id}`
      : `&$or[0][transaction_type]=Token&$or[1][transaction_type]=Balance_Investment&$or[2][transaction_type]=Direct&$or[3][transaction_type]=Token_Refund`
  }${
    paymentStatusFilter?.id ? `&payment_status=${paymentStatusFilter?.id}` : ""
  }`
  const transactionListingGetUrl = `admin-transaction-listing?$limit=${limit}&$skip=${skip}&$sort[updated_at]=-1${filterUrl}`

  const masterData = async () => {
    try {
      setLoading(true)
      const transactionsResponse = await axiosInstance.get(
        transactionListingGetUrl
      )

      if (transactionsResponse) {
        setTotal(transactionsResponse.data.total)
        setData(transactionsResponse.data?.data || transactionsResponse.data)
        let pages = Math.ceil(
          (transactionsResponse.data?.total || transactionsResponse.total) /
            (transactionsResponse.data?.limit || transactionsResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const investmentGetUrl = `investment?$sort[created_at]=-1`
      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse.data?.data || invResponse.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "transactionType":
        setTransactionTypeFilter(e)
        break
      case "paymentStatus":
        setPaymentStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [
    limit,
    skip,
    transactionTypeFilter,
    paymentStatusFilter,
    investmentFilter,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  useEffect(async () => {
    setLoading(true)
    if (selectedUser?.id) {
      try {
        setSelectedInvestment(null)
        const userInvestmentGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&userId=${selectedUser.id}`
        const userInvestmentData = await axiosInstance.get(userInvestmentGetUrl)
        if (userInvestmentData) {
          setUserInvestment(
            (userInvestmentData?.data?.data || userInvestmentData?.data).filter(
              el => el.inv_stage !== "Completed"
            )
          )
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    setLoading(false)
  }, [selectedUser])

  const downloadExcel = async (id, lots, date) => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        transactionTypeFilter?.id
          ? `&$or[0][transaction_type]=${transactionTypeFilter?.id}`
          : `&$or[0][transaction_type]=Token&$or[1][transaction_type]=Balance_Investment&$or[2][transaction_type]=Direct&$or[3][transaction_type]=Token_Refund`
      }${
        paymentStatusFilter?.id
          ? `&payment_status=${paymentStatusFilter?.id}`
          : ""
      }`
      const downloadUrl = `/download-excel?type=transaction&$sort[created_at]=-1${filterUrl}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        if (usersRes) {
          const users = usersRes?.data?.data || usersRes?.data
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const TransactionColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction === "SuperAdmin" ? (
              <Link to="#" className="text-success">
                <Button
                  disabled={
                    order.payment_type != "Online" &&
                    order.payment_status == "initiated"
                      ? false
                      : true
                  }
                  type="button"
                  color="primary"
                  className="btn"
                  onClick={() => handleOrderClick(order)}
                >
                  Update
                </Button>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },

    {
      dataField: "transaction_amount",
      text: "Amount (In Rs)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.transaction_amount),
    },

    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
    },

    {
      dataField: "transaction_type",
      text: "Transaction Type",
      sort: true,
    },

    {
      dataField: "payment_status",
      text: "Payment Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = paymentStatusForTransactions.find(
          obj => obj.id == cellContent
        )
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText}
          </span>
        )
      },
    },

    {
      dataField: "razorpay_order_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "razorpay_payment_id",
      text: "Payment Id",
      sort: true,
    },
    { dataField: "id", text: "Transaction Id", sort: true },
    {
      dataField: "userInvestmentId",
      text: "User Investment Id",
      sort: true,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "dump_id",
      text: "Transaction Dump Id",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  const handleOrderClick = arg => {
    const order = arg
    setUpload(false)
    setBase64("")
    setOrderList({
      id: order.id,
    })
    setIsEdit(true)
    toggle()
  }

  const handleOrderClicks = async () => {
    setUpload(false)
    setBase64("")
    setUsers([])
    setSelectedUser(null)
    setSelectedInvestment(null)
    setTransactionType("")
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  const handleUplaodExcel = e => {
    setUpload(true)
    toggle()
    setErrorData([])
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        payment_status: values.payment_status,
        // created_at: new Date(values.updated_at).toISOString(),
        created_at: getTimestamp(values.updated_at),
      }
      try {
        const transactionResponse = await axiosInstance.patch(
          `transaction/${orderList.id}`,
          updateOrder
        )
        if (transactionResponse) {
          toast.success("Successfully Updated")
          masterData()
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    } else if (upload) {
      const dataToUpload = {
        transactions: base64.replace("data:text/csv;base64,", ""),
      }
      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/upload-base-64?type=transactions",
            dataToUpload
          )
          if (uploadResponse) {
            masterData()
            toast.success("Sucessfully Uploaded")
            setUpload(false)
            setBase64("")
          }
        } catch (error) {
          setErrorData(error?.response?.data?.data)
          const msg = error?.response?.data.message
          setErrorMsg(msg)
          setErrorModal(true)
        }
      } else {
        toast.error("Please select valid file")
        setLoading(false)
        return false
      }
    } else {
      if (!selectedInvestment.id) {
        toast.error("Please select a user investment")
        setLoading(false)
        return false
      }
      const newOrder = {
        userInvestmentId: selectedInvestment?.id,
        userId: selectedInvestment?.userId,
        transaction_amount:
          transactionType == "Token_Refund"
            ? -values.transaction_amount
            : values.transaction_amount,
        payment_type: values.payment_type,
        transaction_type: values.transaction_type,
        payment_status: values.payment_status,
      }
      // save new order

      try {
        const transactionResponse = await axiosInstance.post(
          `transaction`,
          newOrder
        )
        if (transactionResponse) {
          masterData()
          toast.success("Transaction Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        transactionTypeFilter?.id
          ? `&$or[0][transaction_type]=${transactionTypeFilter?.id}`
          : `&$or[0][transaction_type]=Token&$or[1][transaction_type]=Balance_Investment&$or[2][transaction_type]=Direct&$or[3][transaction_type]=Token_Refund`
      }${
        paymentStatusFilter?.id
          ? `&payment_status=${paymentStatusFilter?.id}`
          : ""
      }`
      const url = `admin-transaction-listing?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = TransactionColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          const AmountInRS = item?.transaction_amount / 100
          item["transaction_amount"] = AmountInRS
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleTransactionType = e => {
    setTransactionType(e?.target?.value)
  }

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Repayment,${projectName}`, true)
  }

  const sampleCsv = [
    {
      pan_num: "APUPK0072P",
      userInvestmentId: "683b27c0-be7f-4250-9a4c-5b605afc9016",
      userId: "a2ae90a6-3ac0-45ba-a830-c77b6f1bcd03",
      transaction_type: "Interest_Payment",
      payment_type: "Online",
      transaction_amount: -2000,
      payment_status: "success",
      created_at: "2022-03-02 16:28:00",
      txn_ref_id: 123,
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Transactions" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleOrderClicks}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {accessRestriction >= 2 ||
                  accessRestriction === "SuperAdmin" ? (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleUplaodExcel}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Upload
                    </Button>
                  ) : null}
                  {accessRestriction >= 1 ||
                  accessRestriction === "SuperAdmin" ? (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() =>
                        handleCsvDownload(sampleCsv, "Sample", true)
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i> Sample
                      CSV
                    </Button>
                  ) : null}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => downloadExcel("Transactions")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download Excel
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={TransactionColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-start">
                          <Col md={3}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone `}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Transaction Type</label>
                              <ReactSelect
                                users={transactionTypeForTransactions}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "transactionType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Payment Status</label>
                              <ReactSelect
                                users={paymentStatusForTransactions}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "paymentStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          {/* <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={TransactionColumns(toggle)}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            {Array.isArray(errorData) && errorData?.length ? (
                              <Modal
                                isOpen={errorModal}
                                toggle={() => setErrorModal(false)}
                              >
                                <ModalHeader
                                  toggle={() => setErrorModal(false)}
                                  tag="h4"
                                >
                                  Error Listing
                                </ModalHeader>
                                <ModalBody>
                                  {Array.isArray(errorData) &&
                                    errorData?.length &&
                                    errorData?.map((item, index) => {
                                      return (
                                        <div className="mb-3" key={index}>
                                          <ul>
                                            {errorMsg
                                              ? errorMsg
                                              : "Something Went Wrong"}
                                            <li>
                                              Pan Number :- {item?.pan_num}
                                            </li>
                                            <li>
                                              User Investment Id :-
                                              {item?.userInvestmentId}
                                            </li>
                                            <li>
                                              Payment Type :-{" "}
                                              {item?.payment_type}
                                            </li>
                                            <li>
                                              Transaction Amount:-{" "}
                                              {item?.transaction_amount}
                                            </li>
                                            <li>
                                              {" "}
                                              Transaction Ref Id :-{" "}
                                              {item?.txn_ref_id}
                                            </li>
                                          </ul>
                                        </div>
                                      )
                                    })}
                                </ModalBody>
                              </Modal>
                            ) : null}
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEdit
                                  ? "Update Payment Status"
                                  : upload
                                  ? "Upload Transactions"
                                  : "Add Transaction"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      {upload ? (
                                        <>
                                          <div className="mt-2 mb-3">
                                            {loading && <Loader />}
                                            <Label
                                              htmlFor="formFile"
                                              className="form-label"
                                            >
                                              Upload Transactions
                                            </Label>
                                            <Input
                                              className="form-control"
                                              type="file"
                                              accept=".csv"
                                              id="formFile"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={onSelectFile}
                                            />
                                          </div>
                                        </>
                                      ) : isEdit ? (
                                        <>
                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Status"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="updated_at"
                                              label="Payment Made On (IST)"
                                              type="date"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={moment().format(
                                                "YYYY-MM-DD"
                                              )}
                                            ></AvField>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="mb-3">
                                            <label>User</label>
                                            <ReactSelect
                                              users={users}
                                              searchValue={searchValue}
                                              setSearchValue={handleUserSearch}
                                              selectedOption={selectedUser}
                                              setSelectedOption={
                                                setSelectedUser
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label>User Investment Info</label>
                                            <ReactSelect
                                              isDisabled={!selectedUser?.id}
                                              users={userInvestment}
                                              setSelectedOption={
                                                setSelectedInvestment
                                              }
                                              selectedOption={
                                                selectedInvestment
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "project_name",
                                                "user_name",
                                                "email",
                                                "phone",
                                              ]}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_amount"
                                              label="Transaction Amount (In Paisa)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.transaction_amount ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="payment_type"
                                              label="Payment Type"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="Bank_Transfer"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Online">
                                                Online
                                              </option>
                                              <option value="Bank_Transfer">
                                                Bank Transfer
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_type"
                                              label="Transaction Type"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              onChange={handleTransactionType}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.transaction_type}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Token">
                                                Token
                                              </option>
                                              <option value="Balance_Investment">
                                                Balance Investment
                                              </option>
                                              <option value="Direct">
                                                Direct
                                              </option>
                                              <option value="Token_Refund">
                                                Token Refund
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="initiated"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="initiated">
                                                Initiated
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                            </AvField>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}`}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Transactions.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(Transactions)
