import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import {
  csvDownloadData,
  getAuthorizedSignatory,
  handleValidDate,
  humanize,
  investmentData,
  picUrl,
  usersData,
} from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"
import ReactSelect from "constants/ReactSelect"
import { sortingByAlphabet } from "constants/sort"
import {
  AccessId,
  dematTransferRequestsStatusForStf,
  stfStatus,
} from "constants/ConstantFields"

const StfDocuments = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const [stfDocumentsData, setStfDocumentsData] = useState([])

  const [isEdit, setIsEdit] = useState(false)
  const [currentStf, setCurrentStf] = useState("")
  const [editFormState, setEditFormState] = useState(null)

  const [userSearch, setUserSearch] = useState("")
  const [userList, setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const [investments, setInvestments] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState(null)

  const [topUpList, setTopUpList] = useState([])
  const [selectedTopUp, setSelectedTopUp] = useState(null)

  const [transfereeDesignation, setTransfereeDesignation] = useState("")
  const [executionDate, setExecutionDate] = useState("")

  const [selectedDebentureTrustee, setSelectedDebentureTrustee] = useState(null)
  const [transferorMasterList, setTransferorMasterList] = useState([])
  const [selectedTransferor, setSelectedTransferor] = useState(null)

  const [signFormData, setSignFormData] = useState(null)

  const [selectedDocument, setSelectedDocument] = useState(null)

  const [sendForSignModal, setSendForSignModal] = useState(false)
  const [sendForBulkSignModal, setSendForBulkSignModal] = useState(false)

  const [stfConsent, setStfConsent] = useState(false)
  const [stfBulkConsent, setBulkStfConsent] = useState(false)

  const [cancelStfModal, setCancelStfModal] = useState(false)
  const [cancelConsent, setCancelConsent] = useState(false)

  const [declineStfModal, setDeclineStfModal] = useState(false)
  const [declineConsent, setDeclineConsent] = useState(false)

  const [investmentList, setInvestmentList] = useState([])
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({})
  const [selectedTransferStatus, setSelectedTransferStatus] = useState({})

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${selectedStatus?.id ? `&status=${selectedStatus?.id}` : ""}${
    selectedTransferStatus?.id
      ? `&transferStatus=${selectedTransferStatus?.id}`
      : ""
  }`

  const masterData = async () => {
    try {
      setLoading(true)
      const stfResponse = await axiosInstance.get(
        `stf-document?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (stfResponse) {
        setStfDocumentsData(stfResponse?.data?.data)
        setTotal(stfResponse?.data?.total)
        let pages = Math.ceil(
          (stfResponse.data?.total || stfResponse.total) /
            (stfResponse.data?.limit || stfResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const invResponse = await investmentData()
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "stfStatus":
        setSelectedStatus(e)
        break
      case "transferStatus":
        setSelectedTransferStatus(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, selectedStatus, investmentFilter, selectedTransferStatus])

  const handleSendForSigning = async (e, values) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`stf-signing-request`, {
        stfDocumentId: selectedDocument.id,
        signatory_name: values.signatory_name,
        signatory_email: values.signatory_email,
        signatory_sign_type: values.signatory_sign_type,
        signer_name: values.investor_name,
        investor_sign_type: values.investor_sign_type,
      })
      if (response) {
        toast.success("Sent for sign")
        masterData()
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      toggleSendForSignModal()
      setLoading(false)
    }
  }

  const handleSendForBulkSigning = async (e, values) => {
    try {
      setLoading(true)
      await Promise.all(
        finalSelectedRows.map(row =>
          axiosInstance.post(`stf-signing-request`, {
            stfDocumentId: row.id,
            signatory_name: row.transferor.signatory_name,
            signatory_email: row.transferor.signatory_email,
            signatory_sign_type: values.signatory_sign_type,
            signer_name: row.user_investment_topup.user.user_name,
            investor_sign_type: values.investor_sign_type,
          })
        )
      )
      toast.success(finalSelectedRows.length + " STFs sent for sign")
      masterData()
      setFinalSelectedRows([])
    } catch (error) {
      toast.error(error?.message)
    } finally {
      toggleSendForBulkSignModal()
      setLoading(false)
    }
  }

  const handleCancelStf = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        `stf-signing-request/${selectedDocument?.id}`,
        {
          status: "CANCELLED",
        }
      )
      if (response) {
        toast.success("Document Cancelled")
        masterData()
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      toggleCancelStfModal()
      setLoading(false)
    }
  }

  const handleDeclineStf = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        `stf-document/${selectedDocument?.id}`,
        {
          status: "DECLINED",
          userId: selectedDocument?.user_investment_topup?.userId,
          transferorId: selectedDocument?.transferorId,
        }
      )
      if (response) {
        toast.success("Document Declined!")
        masterData()
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      toggleDeclineStfModal()
      setLoading(false)
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedDocument(row)
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleActionClick = order => {
    setSelectedDocument(order)
    switch (order.status) {
      case "CREATED":
        setSendForSignModal(true)
        setStfConsent(false)
        return
      case "SENT_FOR_SIGN":
        setCancelStfModal(true)
        setCancelConsent(false)
        return
      case "SIGNED":
        setDeclineStfModal(true)
        setDeclineConsent(false)
    }
  }

  const stfDocumentColumns = (download = false) => {
    const cols = [
      {
        dataField: "",
        isDummyField: true,
        text: "Edit/Delete",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            {!order.status ||
            order.status === "DRAFT" ||
            order.status == "CREATED" ||
            order.status == "CANCELLED" ? (
              <>
                <div className="d-flex gap-3">
                  {accessRestriction >= 3 ||
                  accessRestriction == "SuperAdmin" ? (
                    <Link
                      to="#"
                      className="text-success"
                      onClick={() => editStfDocument(order)}
                    >
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id="edittooltip"
                      />
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  ) : (
                    "NA"
                  )}
                  {accessRestriction === "4" ||
                  accessRestriction === "SuperAdmin" ? (
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => deleteStfDocument(order)}
                    >
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  ) : (
                    "NA"
                  )}
                </div>
              </>
            ) : (
              "NA"
            )}
          </>
        ),
      },
      {
        dataField: "",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {(accessRestriction === "4" ||
                accessRestriction === "SuperAdmin") &&
              order.status === "CREATED" &&
              order.unsigned_doc_url ? (
                <Link to="#" className="text-success">
                  <Button
                    type="button"
                    color={
                      order.status === "CREATED"
                        ? "primary"
                        : order.status === "SENT_FOR_SIGN"
                        ? "danger"
                        : "secondary"
                    }
                    className="btn-md btn-rounded"
                    onClick={() => handleActionClick(order)}
                  >
                    {order.status === "CREATED"
                      ? "Sign"
                      : order.status === "SENT_FOR_SIGN"
                      ? "Cancel"
                      : order.status === "SIGNED"
                      ? "Decline"
                      : ""}
                  </Button>
                </Link>
              ) : (
                <>
                  {/* {(accessRestriction === "4" ||
                    accessRestriction === "SuperAdmin") &&
                  order.status === "SENT_FOR_SIGN" ? (
                    <Link to="#" className="text-success">
                      <Button
                        type="button"
                        color={"primary"}
                        className="btn-md btn-rounded"
                        onClick={() => {
                          setSelectedDocument(order)
                          toggleUploadModal()
                        }}
                      >
                        Upload
                      </Button>
                    </Link>
                  ) : (
                    "NA"
                  )} */}
                </>
              )}
            </div>
          </>
        ),
      },
      {
        dataField: "user_investment_topup.investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.user_name",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: download
          ? "dematId"
          : "user_investment_topup.user.user_demat.demat_id",
        text: "Investor Demat Id",
        sort: true,
      },
      {
        dataField: "user_investment_topup.lot_size",
        text: "Bonds",
        sort: true,
      },
      {
        dataField: "user_investment_topup.amount",
        text: "Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(Math.floor(row.user_investment_topup.amount)),
      },
      {
        dataField: "user_investment_topup.created_at",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          handleValidDate(order.user_investment_topup.created_at),
      },
      {
        dataField: "sl_no",
        text: "Serial Number",
        sort: true,
      },
      {
        dataField: "status",
        text: "STF Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = stfStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "demat_transfer_request.status",
        text: "Transfer Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = cellContent
            ? dematTransferRequestsStatusForStf.find(
                obj => obj.id == cellContent
              )
            : { statusText: "NOT INITIATED", colorClassName: "secondary" }
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "unsigned_doc_url",
        text: "Unsigned Doc",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.unsigned_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => window.open(picUrl + order?.unsigned_doc_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <>NA</>
          ),
      },
      {
        dataField: "signed_doc_url",
        text: "Signed Doc",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.signed_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.signed_doc_url)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <>NA</>
          ),
      },
      {
        dataField: download ? "investorSignatory" : "",
        text: "Investor Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties.find(
              data =>
                data?.identifier.toLowerCase() ==
                row?.user_investment_topup?.user?.email.toLowerCase()
            )?.name
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return row.signing_request_data?.signing_parties.find(
              data =>
                data?.identifier.toLowerCase() ==
                row?.user_investment_topup?.user?.email.toLowerCase()
            )?.name
          }
          return ""
        },
      },
      {
        dataField: download ? "investorSignType" : "",
        text: "Investor Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() ==
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return row.signing_request_data?.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() ==
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          }
          return ""
        },
      },
      {
        dataField: "",
        text: "Investor Sign Link",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            (accessRestriction >= 3 || accessRestriction == "SuperAdmin") &&
            row.signing_request_data?.id &&
            (row.webhook_data?.payload?.document?.signing_parties?.length ||
              row.signing_request_data?.signing_parties?.length)
          ) {
            return (
              <a
                href={`${process.env.REACT_APP_DIGIO_BASE_URL}/${row.signing_request_data.id}/earnnest/${row?.user_investment_topup?.user?.email}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
              >
                <i className="mdi mdi-link" style={{ fontSize: "28px" }}></i>
              </a>
            )
          } else {
            return "NA"
          }
        },
      },
      {
        dataField: download ? "investorSignStatus" : "",
        text: "Investor Sign Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return humanize(
              row.webhook_data?.payload.document.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return humanize(
              row.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          }
          return ""
        },
      },
      {
        dataField: download ? "signeeAadharName" : "",
        text: "Signee Aadhar Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return humanize(
              row.webhook_data?.payload.document.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )?.pki_signature_details?.name
            )
          }
          return ""
        },
      },
      {
        dataField: download ? "signeeMatch" : "",
        text: "Signee Match",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties.find(
              data =>
                data?.identifier.toLowerCase() ==
                row?.user_investment_topup?.user?.email.toLowerCase()
            )?.pki_signature_details?.name_validation_score
              ? row.webhook_data?.payload.document.signing_parties.find(
                  data =>
                    data?.identifier.toLowerCase() ==
                    row?.user_investment_topup?.user?.email.toLowerCase()
                )?.pki_signature_details?.name_validation_score + "%"
              : ""
          }
          return ""
        },
      },
      {
        dataField: download ? "transferorSignatory" : "",
        text: "Transferor Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties.find(
              data =>
                data?.identifier.toLowerCase() !=
                row?.user_investment_topup?.user?.email.toLowerCase()
            )?.name
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return row.signing_request_data?.signing_parties.find(
              data =>
                data?.identifier.toLowerCase() !=
                row?.user_investment_topup?.user?.email.toLowerCase()
            )?.name
          }
          return ""
        },
      },
      {
        dataField: download ? "transferorSignType" : "",
        text: "Transferor Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return row.webhook_data?.payload.document.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() !=
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return row.signing_request_data?.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() !=
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          }
          return ""
        },
      },
      {
        dataField: "",
        text: "Transferor Sign Link",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            (accessRestriction >= 3 || accessRestriction == "SuperAdmin") &&
            row.signing_request_data?.id &&
            (row.webhook_data?.payload?.document?.signing_parties?.length ||
              row.signing_request_data?.signing_parties?.length)
          ) {
            return (
              <a
                href={`${process.env.REACT_APP_DIGIO_BASE_URL}/${
                  row.signing_request_data.id
                }/earnnest/${
                  row.webhook_data?.payload?.document?.signing_parties?.length
                    ? row.webhook_data?.payload?.document?.signing_parties.find(
                        data =>
                          data?.identifier.toLowerCase() !=
                          row?.user_investment_topup?.user?.email.toLowerCase()
                      )?.identifier
                    : row.signing_request_data?.signing_parties?.length
                    ? row.signing_request_data?.signing_parties.find(
                        data =>
                          data?.identifier.toLowerCase() !=
                          row?.user_investment_topup?.user?.email.toLowerCase()
                      )?.identifier
                    : ""
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
              >
                <i className="mdi mdi-link" style={{ fontSize: "28px" }}></i>
              </a>
            )
          } else {
            return "NA"
          }
        },
      },
      {
        dataField: download ? "transferorSignStatus" : "",
        text: "Transferor Sign Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row.webhook_data?.payload?.document?.signing_parties?.length) {
            return humanize(
              row.webhook_data?.payload.document.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          }
          if (row.signing_request_data?.signing_parties?.length) {
            return humanize(
              row.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  row?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          }
          return ""
        },
      },
      {
        dataField: download
          ? "lastSignedBy"
          : "webhook_data.payload.document.others.last_signed_by",
        text: "Last Signed By",
        sort: true,
      },
      {
        dataField: download ? "lastSignedOn" : "",
        text: "Last Signed On",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.webhook_data
            ? moment(row.webhook_data?.created_at).format("DD MMM Y hh:mm a")
            : null,
      },
      {
        dataField: "is_signed_manually",
        text: "Is Signed Manually",
        sort: true,
      },
      {
        dataField: "execution_date",
        text: "Execution Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          handleValidDate(order.execution_date),
      },
      {
        dataField: "trade_date",
        text: "Trade Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => handleValidDate(order.trade_date),
      },
      {
        dataField: "transferor.transferor_name",
        text: "Transferor Name",
        sort: true,
      },
      {
        dataField: "transferee_designation",
        text: "Transferee Designation",
        sort: true,
      },
      {
        dataField: "guardian_name",
        text: "Guardian Name",
        sort: true,
      },
      {
        dataField: "settlement_days",
        text: "Settlement Days",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.relManager.user_name",
        text: "RM Name",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.email",
        text: "Investor Email",
        sort: true,
      },
      {
        dataField: "user_investment_topup.user.phone",
        text: "Investor Phone",
        sort: true,
      },
      {
        dataField: download
          ? "panName"
          : "user_investment_topup.user.user_pan.name",
        text: "Name as per PAN",
        sort: true,
      },
      {
        dataField: download
          ? "panNumber"
          : "user_investment_topup.user.user_pan.pan_number",
        text: "Investor PAN",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => handleValidDate(order.created_at),
      },
    ]
    return cols
  }

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }
  const toggleUploadModal = () => {
    setIsUploadModalOpen(!isUploadModalOpen)
  }
  const toggleSendForSignModal = () => {
    setSendForSignModal(!sendForSignModal)
  }
  const toggleSendForBulkSignModal = () => {
    setBulkStfConsent(false)
    setSendForBulkSignModal(!sendForBulkSignModal)
  }
  const toggleCancelStfModal = () => {
    setCancelStfModal(!cancelStfModal)
  }
  const toggleDeclineStfModal = () => {
    setDeclineStfModal(!declineStfModal)
  }

  const generateStf = () => {
    setIsEdit(false)
    toggle()
  }
  const editStfDocument = async order => {
    setIsEdit(true)
    setLoading(true)
    setCurrentStf(order.id)
    setSelectedDocument(order)
    const userRes = await usersData()
    const investmentRes = await investmentData()

    const users = userRes?.data?.data || userRes?.data
    const investments = investmentRes?.data?.data || investmentRes?.data

    setUserList(users)
    const selectedUser = users?.find(
      el => el.id == order.user_investment_topup.userId
    )
    setSelectedUser(selectedUser)

    const currentInv = investments?.find(
      val => val.id == order.user_investment_topup.investmentId
    )
    setSelectedInvestment(currentInv)

    setSelectedDebentureTrustee(currentInv.debenture_trustee)

    const topUpGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&userId=${order.user_investment_topup.userId}&investmentId=${order.user_investment_topup.investmentId}&topup=true&inv_stage=Completed`
    const topUpRes = await axiosInstance.get(topUpGetUrl)
    if (topUpRes) {
      const topUpData = topUpRes?.data?.data || topUpRes?.data
      setTopUpList(topUpData)
      setSelectedTopUp(
        topUpData.find(topup => topup?.id === order.userInvestmentTopupId)
      )
    }
    setEditFormState({
      transferee_designation: order.transferee_designation,
      guardian_name: order.guardian_name,
      settlement_days: order.settlement_days,
      trade_date: moment(order.trade_date).format("YYYY-MM-DD"),
    })

    setExecutionDate(moment(order.execution_date).format("YYYY-MM-DD"))

    const transferorMasterRes = await axiosInstance.get(`transferor-master`)
    const transferorMasterData =
      transferorMasterRes?.data?.data || transferorMasterRes?.data
    setTransferorMasterList(transferorMasterData)
    setSelectedTransferor(
      transferorMasterData.find(el => el.id === order.transferorId)
    )

    setLoading(false)
    toggle()
  }

  const deleteStfDocument = async order => {
    var r = confirm(`Are you sure want to delete?`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(`stf-document/${order.id}`)
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleValidStfSubmit = async (e, values) => {
    if (!selectedTransferor?.id) {
      toast.error("Please select a Transferor")
      return
    }
    if (selectedUser?.id && !selectedUser?.user_pan) {
      toast.error("User Pan details not found")
      return
    }
    if (isEdit) {
      try {
        setLoading(true)
        const payload = {
          userInvestmentTopupId: selectedTopUp?.id,
          transferee_designation: values.transferee_designation,
          guardian_name:
            transfereeDesignation === "Guardian" ? values.guardian_name : null,
          execution_date: executionDate,
          trade_date: values.trade_date,
          settlement_days: values.settlement_days,
          transferorId: selectedTransferor.id,
          investmentId: selectedInvestment.id,
          userId: selectedUser.id,
          webhook_data: null,
          signing_request_data: null,
          digio_document_id: null,
        }
        const response = await axiosInstance.patch(
          `/stf-document/${currentStf}`,
          payload
        )
        if (response) {
          const pdfResponse = await axiosInstance.post("/generate-pdf", {
            type: "STF",
            stfDocumentId: response.data.id,
          })
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        }
        toggle()
      } catch (error) {
        toast.error("Error while generating PDF")
      } finally {
        masterData()
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        const payload = {
          userInvestmentTopupId: selectedTopUp?.id,
          transferee_designation: values.transferee_designation,
          guardian_name:
            transfereeDesignation === "Guardian" ? values.guardian_name : null,
          execution_date: executionDate,
          trade_date: values.trade_date,
          settlement_days: values.settlement_days,
          transferorId: selectedTransferor.id,
          investmentId: selectedInvestment.id,
          userId: selectedUser.id,
          status: "DRAFT",
        }
        const response = await axiosInstance.post("/stf-document", payload)
        if (response) {
          const pdfResponse = await axiosInstance.post("/generate-pdf", {
            type: "STF",
            stfDocumentId: response.data.id,
          })
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        }
        toggle()
      } catch (error) {
        toast.error("Error while generating PDF")
      } finally {
        masterData()
        setLoading(false)
      }
    }
  }

  const handleUserSearch = async e => {
    setUserSearch(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUserList(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUserList([])
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const handleProjectName = e => {
    const currentInv = investments.find(val => val.id == e.target.value)
    setSelectedInvestment(currentInv)
  }
  const handleTopUp = e => {
    const selectedTopUp = topUpList.find(val => val.id == e.target.value)
    setSelectedTopUp(selectedTopUp)
  }

  useEffect(async () => {
    if (modal) {
      try {
        setLoading(true)
        const invResponse = await investmentData("Approved")
        if (invResponse) {
          setInvestments(invResponse?.data?.data || invResponse?.data)
        }
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    } else {
      setUserList([])
      setSelectedUser(null)
      setInvestments([])
      setSelectedInvestment(null)
      setTopUpList([])
      setSelectedTopUp(null)
      setEditFormState(null)
      setExecutionDate("")
    }
  }, [modal])

  useEffect(async () => {
    if (selectedTopUp?.id) {
      setLoading(true)
      try {
        if (!isEdit) {
          setSelectedTransferor(null)
          const transferorMasterRes = await axiosInstance.get(
            `transferor-master`
          )
          const transferorList =
            transferorMasterRes?.data?.data || transferorMasterRes?.data

          setTransferorMasterList(transferorList)
          setExecutionDate(selectedTopUp.created_at)
          if (selectedTopUp.transferorId) {
            const transferor = transferorList.find(
              val => val.id == selectedTopUp.transferorId
            )
            console.log(transferor)

            setSelectedTransferor(transferor)
          }
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [selectedTopUp])

  useEffect(async () => {
    setLoading(true)
    if (selectedUser?.id && !selectedUser?.user_pan) {
      toast.error("User Pan details not found")
    }
    setSelectedTopUp(null)
    if (!isEdit && selectedUser?.id && selectedInvestment?.id) {
      try {
        setSelectedDebentureTrustee(selectedInvestment.debenture_trustee)
        const topUpGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&userId=${selectedUser.id}&investmentId=${selectedInvestment.id}&topup=true&inv_stage=Completed`
        const topUpData = await axiosInstance.get(topUpGetUrl)
        if (topUpData) {
          setTopUpList(
            (topUpData?.data?.data || topUpData?.data).filter(
              topup => topup?.stfDocumentId === null
            )
          )
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    setLoading(false)
  }, [selectedUser, selectedInvestment])

  useEffect(() => {
    if (selectedUser?.id && executionDate) {
      if (selectedUser?.user_pan) {
        setTransfereeDesignation(
          getAuthorizedSignatory(selectedUser, executionDate)
        )
      }
    }
  }, [executionDate])

  useEffect(async () => {
    if (sendForSignModal) {
      setLoading(true)
      try {
        const transferorMasterRes = await axiosInstance.get(
          `transferor-master/${selectedDocument?.transferorId}`
        )
        const transferorMasterData =
          transferorMasterRes?.data?.data || transferorMasterRes?.data
        setSignFormData({
          transferor_name: transferorMasterData?.transferor_name,
          signatory_name: transferorMasterData?.signatory_name,
          signatory_email: transferorMasterData?.signatory_email,
          signatory_sign_type: transferorMasterData?.sign_type,
          investor_name:
            selectedDocument?.user_investment_topup?.user?.user_pan?.name,
          investor_email: selectedDocument?.user_investment_topup?.user?.email,
          investor_sign_type: "AADHAAR",
          investor_pan_type:
            selectedDocument?.user_investment_topup?.user?.user_pan?.pan_type,
        })
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [sendForSignModal])
  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const downloadData = async fileName => {
    try {
      setLoading(true)

      const res = await axiosInstance.get(
        `stf-document?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = stfDocumentColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item.user_investment_topup.amount = handleAmount(
            Math.floor(item.user_investment_topup.amount)
          )

          item.user_investment_topup.created_at = handleValidDate(
            item.user_investment_topup.created_at
          )

          item.status = humanize(item.status)

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.investorSignatory =
              item.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.name
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.investorSignatory =
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.name
          } else {
            item.investorSignatory = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.investorSignType = item.signing_request_data?.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.investorSignType =
              item.webhook_data?.payload?.document?.signing_parties
                .find(
                  data =>
                    data?.identifier.toLowerCase() ==
                    item?.user_investment_topup?.user?.email.toLowerCase()
                )
                ?.signature_type.toUpperCase()
          } else {
            item.investorSignType = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.investorSignStatus = humanize(
              item.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.investorSignStatus = humanize(
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          } else {
            item.investorSignStatus = ""
          }

          if (item.webhook_data?.payload?.document?.signing_parties?.length) {
            item.signeeAadharName = humanize(
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.pki_signature_details?.name
            )
          } else {
            item.signeeAadharName = ""
          }
          if (item.webhook_data?.payload?.document?.signing_parties?.length) {
            item.signeeMatch =
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() ==
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.pki_signature_details?.name_validation_score
                ? item.webhook_data?.payload?.document?.signing_parties.find(
                    data =>
                      data?.identifier.toLowerCase() ==
                      item?.user_investment_topup?.user?.email.toLowerCase()
                  )?.pki_signature_details?.name_validation_score + "%"
                : ""
          } else {
            item.signeeMatch = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.transferorSignatory =
              item.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.name
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.transferorSignatory =
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.name
          } else {
            item.transferorSignatory = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.transferorSignType = item.signing_request_data?.signing_parties
              .find(
                data =>
                  data?.identifier.toLowerCase() !=
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )
              ?.signature_type.toUpperCase()
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.transferorSignType =
              item.webhook_data?.payload?.document?.signing_parties
                .find(
                  data =>
                    data?.identifier.toLowerCase() !=
                    item?.user_investment_topup?.user?.email.toLowerCase()
                )
                ?.signature_type.toUpperCase()
          } else {
            item.transferorSignType = ""
          }

          if (
            !item.webhook_data &&
            item.signing_request_data?.signing_parties?.length
          ) {
            item.transferorSignStatus = humanize(
              item.signing_request_data?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          } else if (
            item.webhook_data?.payload?.document?.signing_parties?.length
          ) {
            item.transferorSignStatus = humanize(
              item.webhook_data?.payload?.document?.signing_parties.find(
                data =>
                  data?.identifier.toLowerCase() !=
                  item?.user_investment_topup?.user?.email.toLowerCase()
              )?.status
            )
          } else {
            item.transferorSignStatus = ""
          }

          item.lastSignedBy =
            item.webhook_data?.payload?.document?.others?.last_signed_by

          item.lastSignedOn = item.webhook_data
            ? moment(item.webhook_data?.created_at).format("DD MMM Y hh:mm a")
            : null

          item.execution_date = handleValidDate(item.execution_date)

          item.trade_date = handleValidDate(item.trade_date)

          item.panName = item.user_investment_topup?.user?.user_pan?.name

          item.panNumber =
            item.user_investment_topup?.user?.user_pan?.pan_number

          item.dematId = item.user_investment_topup?.user?.user_demat?.demat_id

          item.created_at = handleValidDate(item.created_at)
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleManuallyUploadSignedDoc = async (e, values) => {
    try {
      const file = selectedFile?.target?.files[0]
      if (!file) {
        toast.error("Select a file")
        return
      }
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append(
        "investmentId",
        selectedDocument.user_investment_topup.investmentId
      )
      formData.append("serial_no", selectedDocument.sl_no)
      const uploadResponse = await axiosInstance.post(
        `/file-upload/?type=manually_stf_signed_document`,
        formData
      )
      const payload = {
        status: "SIGNED",
        signed_doc_url: uploadResponse.data[0].name,
        // manually_signed_on: values.doc_signed_date,
        is_signed_manually: true,
        userId: selectedDocument.user_investment_topup.userId,
        transferorId: selectedDocument.transferorId,
      }
      const portfolioDocPayload = {
        investmentId: selectedDocument.user_investment_topup.investmentId,
        userId: selectedDocument.user_investment_topup.userId,
        documentTypeId: "83262ac2-a8b4-463b-b889-c17ad6e0f7e5",
        url: uploadResponse.data[0].name,
        document_name: "Securities Transfer Form",
        document_desc: "Securities Transfer Form",
      }
      await axiosInstance.patch(`/stf-document/${selectedDocument.id}`, payload)
      await axiosInstance.post(`/document`, portfolioDocPayload)
      toast.success("Uploaded Successfully")
      setFinalSelectedRows([])
      toggleUploadModal()
      masterData()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="STF Documents" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={generateStf}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Generate STF
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => {
                        toggleUploadModal()
                      }}
                      disabled={
                        !(
                          finalSelectedRows?.length == 1 &&
                          (finalSelectedRows[0]?.status == "SENT_FOR_SIGN" ||
                            finalSelectedRows[0]?.status == "CREATED" ||
                            finalSelectedRows[0]?.status == "DRAFT")
                        )
                      }
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Upload
                      STF Document
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={toggleSendForBulkSignModal}
                      disabled={
                        finalSelectedRows?.length === 0 ||
                        finalSelectedRows?.some(
                          row => row?.status !== "CREATED"
                        )
                      }
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Bulk Sign
                    </Button>
                  )}
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={() => downloadData("STF Documents")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={stfDocumentsData}
                    columns={stfDocumentColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>STF Status</label>
                              <ReactSelect
                                users={stfStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "stfStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Transfer Status</label>
                              <ReactSelect
                                users={dematTransferRequestsStatusForStf}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "transferStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={stfDocumentColumns()}
                              data={stfDocumentsData}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEdit && selectedDocument?.unsigned_doc_url
                                  ? "Regenerate STF"
                                  : "Generate STF"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidStfSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="row">
                                        <div className="mb-3">
                                          <label>Select User</label>
                                          <ReactSelect
                                            users={userList}
                                            searchValue={userSearch}
                                            setSearchValue={handleUserSearch}
                                            selectedOption={selectedUser}
                                            setSelectedOption={setSelectedUser}
                                            multiOptionLabel={false}
                                            optionLabelKeys={[
                                              "user_pan?.name",
                                              "email",
                                              "phone",
                                              "user_pan?.pan_number",
                                            ]}
                                            isDisabled={isEdit}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Select Project"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={selectedInvestment?.id}
                                            onChange={handleProjectName}
                                            disabled={isEdit}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="topup"
                                            label="Select Top-Up"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={selectedTopUp?.id || ""}
                                            onChange={handleTopUp}
                                            disabled={
                                              !selectedUser?.id ||
                                              !selectedInvestment?.id ||
                                              isEdit
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              topUpList?.map((item, index) => (
                                                <option
                                                  key={item?.id}
                                                  value={item?.id}
                                                >
                                                  {item?.project_name}; Lots:{" "}
                                                  {item?.lot_size}; Amount:{" "}
                                                  {handleAmount(item?.amount)};
                                                  Date:{" "}
                                                  {moment(
                                                    item?.created_at
                                                  ).format("DD-MM-YYYY")}
                                                </option>
                                              ))
                                            )}
                                          </AvField>
                                        </div>
                                        {selectedTopUp && (
                                          <>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="transferee_designation"
                                                label="Transferee Designation"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={transfereeDesignation}
                                                disabled={true}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="execution_date"
                                                label="Execution Date"
                                                type="date"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={true}
                                                value={moment(
                                                  executionDate
                                                ).format("YYYY-MM-DD")}
                                                onChange={e =>
                                                  setExecutionDate(
                                                    e.target.value
                                                  )
                                                }
                                              ></AvField>
                                            </div>
                                            {transfereeDesignation ===
                                            "Guardian" ? (
                                              <div className="mb-3 col-md-12">
                                                <AvField
                                                  name="guardian_name"
                                                  label="Guardian Name"
                                                  type="text"
                                                  errorMessage="Invalid value"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    editFormState?.guardian_name ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            ) : null}
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="settlement_days"
                                                label="Settlement Days"
                                                type="number"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  selectedInvestment
                                                    ?.investment_tranche
                                                    ?.settlement_days || 5
                                                }
                                                disabled={true}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="trade_date"
                                                label="Trade Date"
                                                type="date"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={true}
                                                value={moment(
                                                  editFormState?.trade_date ||
                                                    selectedTopUp.created_at
                                                ).format("YYYY-MM-DD")}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="debenture_trustee_name"
                                                label="Debenture Trustee Name"
                                                type="text"
                                                errorMessage="Invalid value"
                                                disabled={true}
                                                value={
                                                  selectedDebentureTrustee?.debenture_trustee_name
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="debenture_trustee_cin"
                                                label="Debenture Trustee CIN"
                                                type="text"
                                                errorMessage="Invalid value"
                                                disabled={true}
                                                value={
                                                  selectedDebentureTrustee?.debenture_trustee_cin
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <label>Select Transferor</label>
                                              <ReactSelect
                                                users={transferorMasterList}
                                                setSelectedOption={
                                                  setSelectedTransferor
                                                }
                                                multiOptionLabel={true}
                                                optionLabelKeys={[
                                                  "transferor_name",
                                                  "transferor_depository_name",
                                                  "dp_name",
                                                  "dp_id",
                                                  "client_id",
                                                ]}
                                                selectedOption={
                                                  selectedTransferor
                                                }
                                                isDisabled={
                                                  selectedTopUp.transferorId ||
                                                  isEdit
                                                }
                                              />
                                            </div>{" "}
                                          </>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <Button
                                          type="submit"
                                          color="success"
                                          className="save-user"
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={sendForSignModal}
                              toggle={toggleSendForSignModal}
                            >
                              <ModalHeader
                                toggle={toggleSendForSignModal}
                                tag="h4"
                              >
                                Send for Sign
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleSendForSigning}>
                                  <h5 className="mb-3">
                                    Please verify the details of the signing
                                    parties before sending it for sign.
                                  </h5>
                                  <Row>
                                    <Col>
                                      <div className="row">
                                        <h5 className="mb-3">Signer 1</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="transferor_name"
                                            label="Transferor Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              signFormData?.transferor_name ||
                                              ""
                                            }
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="signatory_sign_type"
                                            label="Transferor Signatory Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            value={
                                              signFormData?.signatory_sign_type ||
                                              ""
                                            }
                                          >
                                            <option value="AADHAAR">
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="signatory_name"
                                            label="Transferor Signatory Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              signFormData?.signatory_name || ""
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="signatory_email"
                                            label="Transferor Signatory Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              signFormData?.signatory_email ||
                                              ""
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <h5 className="mb-3">Signer 2</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="investor_name"
                                            label="Signer Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={signFormData?.investor_name}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={
                                              !(
                                                accessRestriction ===
                                                  "SuperAdmin" &&
                                                (signFormData?.investor_pan_type ===
                                                  "Company" ||
                                                  signFormData?.investor_pan_type ===
                                                    "Firm/ Limited Liability Partnership")
                                              )
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="investor_email"
                                            label="Investor Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            disabled={true}
                                            value={signFormData?.investor_email}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="investor_sign_type"
                                            label="Investor Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            value={
                                              signFormData?.investor_sign_type
                                            }
                                          >
                                            <option value="AADHAAR">
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <div className="d-flex gap-1 mb-3">
                                          <AvField
                                            name="stf_consent"
                                            type="checkbox"
                                            id="stfConsent"
                                            checked={stfConsent}
                                            onChange={e =>
                                              setStfConsent(e.target.checked)
                                            }
                                          />{" "}
                                          <label
                                            className="mb-0 font-size-14 text-muted"
                                            htmlFor="stfConsent"
                                          >
                                            I have reviewed the document and
                                            verified the signer details
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="col-auto">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setSendForSignModal(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                    <div className="col-auto">
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!stfConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={sendForBulkSignModal}
                              toggle={toggleSendForBulkSignModal}
                            >
                              <ModalHeader
                                toggle={toggleSendForBulkSignModal}
                                tag="h4"
                              >
                                Bulk Send Signing Requests
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={handleSendForBulkSigning}
                                >
                                  <Row>
                                    <Col>
                                      <div className="row">
                                        <div className="mb-3">
                                          <AvField
                                            name="signatory_sign_type"
                                            label="Transferor Signatory Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            value={"AADHAAR"}
                                          >
                                            <option value="AADHAAR">
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="investor_sign_type"
                                            label="Investor Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            value={"AADHAAR"}
                                          >
                                            <option value="AADHAAR">
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <div className="d-flex gap-1 mb-3">
                                          <AvField
                                            name="stf_consent"
                                            type="checkbox"
                                            id="stfBulkConsent"
                                            checked={stfBulkConsent}
                                            onChange={e =>
                                              setBulkStfConsent(
                                                e.target.checked
                                              )
                                            }
                                          />
                                          <label
                                            className="mb-0 font-size-14 text-muted"
                                            htmlFor="stfBulkConsent"
                                          >
                                            I want to send{" "}
                                            {finalSelectedRows.length} document
                                            for signing
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="col-auto">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setSendForBulkSignModal(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                    <div className="col-auto">
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!stfBulkConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={cancelStfModal}
                              toggle={toggleCancelStfModal}
                            >
                              <ModalHeader
                                toggle={toggleCancelStfModal}
                                tag="h4"
                              >
                                Confirmation
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleCancelStf}>
                                  <Row>
                                    <h5>
                                      The STF has already been sent for Sign.
                                    </h5>
                                    <p className="font-size-15">
                                      {" "}
                                      {selectedDocument?.webhook_data ? (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedDocument?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedDocument?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedDocument?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedDocument?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      ) : (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedDocument
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedDocument
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedDocument
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedDocument
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      )}
                                    </p>
                                    <div className="d-flex gap-1 my-3">
                                      <AvField
                                        name="cancel_consent"
                                        type="checkbox"
                                        id="cancelConsent"
                                        checked={cancelConsent}
                                        onChange={e =>
                                          setCancelConsent(e.target.checked)
                                        }
                                      />{" "}
                                      <label
                                        className="mb-0 font-size-14 text-muted"
                                        htmlFor="cancelConsent"
                                      >
                                        I want to cancel this STF document
                                      </label>
                                    </div>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="col-auto">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() => setCancelStfModal(false)}
                                      >
                                        Close
                                      </Button>
                                    </div>
                                    <div className="col-auto">
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!cancelConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={declineStfModal}
                              toggle={toggleDeclineStfModal}
                            >
                              <ModalHeader
                                toggle={toggleDeclineStfModal}
                                tag="h4"
                              >
                                Confirmation
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleDeclineStf}>
                                  <Row>
                                    <h5>Are you sure you want to Decline?</h5>
                                    <p>
                                      The STF has already been Signed by both
                                      the parties.
                                    </p>
                                    <div className="d-flex gap-1 my-3">
                                      <AvField
                                        name="decline_consent"
                                        type="checkbox"
                                        id="declineConsent"
                                        checked={declineConsent}
                                        onChange={e =>
                                          setDeclineConsent(e.target.checked)
                                        }
                                      />{" "}
                                      <label
                                        className="mb-0 font-size-14 text-muted"
                                        htmlFor="declineConsent"
                                      >
                                        I want to decline this STF document
                                      </label>
                                    </div>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="col-auto">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setDeclineStfModal(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                    </div>
                                    <div className="col-auto">
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!declineConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={isUploadModalOpen}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Manually Signed Document
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={handleManuallyUploadSignedDoc}
                                >
                                  {/* <div className="mb-3">
                                    <AvField
                                      name="doc_signed_date"
                                      label="Manully Signed on"
                                      type="date"
                                      errorMessage="Invalid Value"
                                      validate={{
                                        required: { value: true },
                                      }}
                                    ></AvField>
                                  </div> */}
                                  <div className="mb-3">
                                    <AvField
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                      errorMessage="Please select a file"
                                      validate={{
                                        required: {
                                          value: true,
                                        },
                                      }}
                                      name="signedDoc"
                                      label="Select File"
                                      accept=".pdf"
                                      onChange={setSelectedFile}
                                    ></AvField>
                                  </div>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

StfDocuments.propTypes = {
  stfDocumentsData: PropTypes.array,
}

export default withRouter(StfDocuments)
