import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  csvDownloadData,
  generateFinancialYearList,
  humanize,
} from "constants/common"
import {
  AccessId,
  addendumStatus,
  distributorInvoiceReportStatus,
  StatusObjForDistributorReport,
  stfStatus,
} from "constants/ConstantFields"
import nodata from "../../assets/images/nodata/no-data.svg"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"

const distributorReport = () => {
  const [selectFeeType, setSelectFeeType] = useState({})
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [orders, setData] = useState([])
  const accessRestriction = DealManagementAccess(
    AccessId?.DISTRIBUTOR_MANAGEMENT
  )
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [investments, setInvestments] = useState()
  const [distributorUsers, setDistributorUsers] = useState()
  const [relManagerUsers, setRelManagerUsers] = useState()

  const [selectedProject, setSelectedProject] = useState()
  const [selectedDistributor, setSelectedDistributor] = useState()
  const [selectedRm, setSelectedRm] = useState()

  const [showTrailData, setShowTrailData] = useState(false)
  const [selectedStfStatusFilter, setSelectedStfStatusFilter] = useState()
  const [addendumStatusFilter, setAddendumStatusFilter] = useState()
  const [invoiceStatusFilter, setInvoiceStatusFilter] = useState()
  const [userFilter, setUserFilter] = useState("")

  const addendumSignedFilterList = [
    { id: 1, value: "null", text: "NOT CREATED" },
    { id: 2, value: "CREATED", text: "CREATED" },
    { id: 3, value: "SENT_FOR_SIGN", text: "SENT FOR SIGN" },
    { id: 4, value: "CANCELLED", text: "CANCELLED" },
    { id: 5, value: "SIGNED", text: "SIGNED" },
  ]

  const stfStatusFilterList = [
    { id: 1, value: "null", text: "NOT CREATED" },
    { id: 2, value: "CREATED", text: "CREATED" },
    { id: 3, value: "SENT_FOR_SIGN", text: "SENT FOR SIGN" },
    { id: 4, value: "SIGNED", text: "SIGNED" },
    { id: 5, value: "CANCELLED", text: "CANCELLED" },
  ]

  const [selectedRow, setSelectedRow] = useState(null)
  const [financialYearFilters, setFinancialYearFilters] = useState([])
  const [selectedFinacialYearFilter, setSelectedFinacialYearFilter] =
    useState(null)

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const handleActionClick = order => {
    setSelectedRow(order)
    toggle()
  }

  const DistributorReportColumns = download => {
    const columns = []
    // if (selectFeeType?.id === "upfront") {
    //   columns.push({
    //     dataField: "",
    //     text: "Action",
    //     sort: true,
    //     // eslint-disable-next-line react/display-name
    //     formatter: (cellContent, order) => (
    //       <>
    //         <div className="d-flex gap-3">
    //           {(accessRestriction >= "3" ||
    //             accessRestriction === "SuperAdmin") &&
    //           !order.is_upfront_fee_paid ? (
    //             <Link to="#" className="text-success">
    //               <Button
    //                 type="button"
    //                 color={"primary"}
    //                 className="btn-md btn-rounded"
    //                 onClick={() => handleActionClick(order)}
    //               >
    //                 Mark as Paid
    //               </Button>
    //             </Link>
    //           ) : (
    //             "NA"
    //           )}
    //         </div>
    //       </>
    //     ),
    //   })
    // }
    columns.push(
      {
        dataField: "projectName",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "distributorName",
        text: "Distributor Name",
        sort: true,
      },
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
      },
      {
        dataField: "nameOnPan",
        text: " Investor Name on PAN",
        sort: true,
      },
      {
        dataField: "user_pan_number",
        text: "Investor PAN Number",
        sort: true,
      },
      {
        dataField: "userEmail",
        text: "Investor Email",
        sort: true,
      },
      {
        dataField: "investmentDate",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.investmentDate),
      }
    )
    if (selectFeeType?.id === "upfront") {
      columns.push(
        {
          dataField: "invoice_status",
          text: "Invoice Status",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            const status = distributorInvoiceReportStatus.find(
              obj => obj.id == cellContent
            )
            return (
              <span
                className={`badge badge-pill font-size-12 badge-soft-${
                  status ? status?.colorClassName : "secondary"
                } text-capitalize`}
              >
                {status?.statusText ||
                  cellContent?.toLowerCase()?.replaceAll("_", " ")}
              </span>
            )
          },
        },
        {
          dataField: "invoice_date",
          text: "Invoice Date",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            row.invoice_date ? handleValidDate(row.invoice_date) : "",
        },
        {
          dataField: "invoice_number",
          text: "Invoice No.",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => (
            <div className="text-end">{cellContent}</div>
          ),
        }
      )
    }
    columns.push(
      {
        dataField: "stf_status",
        text: "STF Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = stfStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "addendum_status",
        text: "Addendum Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = addendumStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "addendum_no",
        text: "Addendum No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      }
    )
    if (selectFeeType) {
      if (selectFeeType.id === "trail") {
        columns.push(
          {
            dataField: "lot_size",
            text: "No. of Bonds",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">{cellContent}</div>
            ),
          },
          {
            dataField: "trailFeeStartDate",
            text: "Trail Fees St. date",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) =>
              handleValidDate(row.trailFeeStartDate),
          },
          {
            dataField: "openingOutstandingPrincipal",
            text: "Outstanding Principal (Open)",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">
                {handleAmount(row.openingOutstandingPrincipal)}
              </div>
            ),
          },
          {
            dataField: "closingOutstandingPrinicipal",
            text: "Outstanding Principal (Close)",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">
                {handleAmount(row.closingOutstandingPrinicipal)}
              </div>
            ),
          },
          {
            dataField: "trailFeePercent",
            text: "Trail Fee %",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">{cellContent}</div>
            ),
          },
          {
            dataField: "trailFee",
            text: "Trail Fees Amount",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">{handleAmount(row.trailFee)}</div>
            ),
          }
        )
      } else if (selectFeeType.id === "upfront") {
        columns.push(
          // {
          //   dataField: "is_upfront_fee_paid",
          //   text: "Is Upfront Fee Paid",
          //   sort: true,
          // },
          {
            dataField: "lot_size",
            text: "No. of Bonds",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">{cellContent}</div>
            ),
          },
          {
            dataField: "outstandingPrincipal",
            text: "Outstanding Principal",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              return (
                <div className="text-end">
                  {handleAmount(row.faceValue - row.redeemedFaceValue)}
                </div>
              )
            },
          },
          {
            dataField: "upfront_fee_percent",
            text: "Upfront Fee %",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">
                {cellContent ? Number(cellContent).toFixed(2) : "0"}
              </div>
            ),
          },
          {
            dataField: "upfrontFee",
            text: "Upfront Fee",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
              <div className="text-end">
                {cellContent ? handleAmount(row.upfrontFee) : ""}
              </div>
            ),
          }
          // {
          //   dataField: "distributor_pan_number",
          //   text: "Distributor Pan Number",
          //   sort: true,
          // }
        )
      }
    }
    columns.push(
      {
        dataField: "relManagerName",
        text: "Relationship Manager",
        sort: true,
      },
      {
        dataField: "distributorEmail",
        text: "Distributor Email",
        sort: true,
      },
      {
        dataField: "distributorPhone",
        text: "Distributor Phone",
        sort: true,
      },
      {
        dataField: "userName",
        text: "Investor Name",
        sort: true,
      },

      {
        dataField: "userPhone",
        text: "Investor Phone",
        sort: true,
      }
    )
    columns.push({
      dataField: "topupId",
      text: "TopUp Id",
      sort: true,
    })
    return columns
  }

  const defaultSorted = [
    {
      dataField: "distributorId",
      order: "desc",
    },
  ]

  const filterUrl = `${
    selectFeeType?.id ? `type=${selectFeeType.id}` : `type=${"upfront"}`
  }${
    selectFeeType?.id == "trail" && startDate ? `&startDate=${startDate}` : ``
  }${selectFeeType?.id == "trail" && endDate ? `&endDate=${endDate}` : ``}${
    selectedProject ? `&investmentId=` + selectedProject?.id : ""
  }${selectedDistributor ? `&distributorId=` + selectedDistributor?.id : ""}${
    selectedRm ? `&relManagerId=` + selectedRm?.id : ""
  }${
    selectedFinacialYearFilter
      ? `&startDate=${selectedFinacialYearFilter.startDate}&endDate=${selectedFinacialYearFilter.endDate}`
      : ``
  }${
    selectedStfStatusFilter?.id
      ? `&stf_status=${selectedStfStatusFilter.value}`
      : ""
  }${
    addendumStatusFilter?.id
      ? `&addendum_status=${addendumStatusFilter.value}`
      : ""
  }${
    invoiceStatusFilter?.id ? `&invoice_status=${invoiceStatusFilter.id}` : ""
  }${userFilter.length >= 3 ? `&name=${userFilter}` : ``}`
  // MasterData fn to call the data from backend.
  const getUrl = `/distributor-fees?${filterUrl}&$limit=${limit}&$skip=${skip}`
  const masterData = async () => {
    setLoading(true)
    try {
      setData([])
      setTotal(0)
      setTotalPage(0)
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setData(
          data.map(item => ({
            ...item,
          }))
        )
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }

      const investmentRes = await axiosInstance.get(`investment`)
      const investmentData = investmentRes?.data?.data || investmentRes?.data
      setInvestments(investmentData)

      const usersRes = await axiosInstance.get(`users?permissions=OPS_USER`)
      const usersData = usersRes?.data?.data || usersRes?.data
      setRelManagerUsers(usersData.filter(user => user.roleId === 1))
      setDistributorUsers(
        usersData?.filter(user => {
          if (user.role?.department === "Distributor") {
            user.user_name = user.distributor_kyc?.name
            return true
          }
        })
      )
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  // function to change the state of date i/p form
  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])
  useEffect(async () => {
    masterData()
  }, [limit, skip, selectedFinacialYearFilter])
  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "feeType":
        setSelectFeeType(e)
        setSelectedProject(null)
        setSelectedStfStatusFilter()
        setAddendumStatusFilter()
        setSelectedFinacialYearFilter(null)
        setFinancialYearFilters([])
        setSelectedDistributor(null)
        setSelectedRm(null)
        setShowTrailData(false)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }
  const handleStartDate = e => {
    const date = e.target.value
    setStartDate(date)
  }
  const handleEndDate = e => {
    const date = e.target.value
    setEndDate(date)
  }

  const handleSearch = () => {
    if (startDate && endDate && selectFeeType?.id === "trail") {
      setShowTrailData(true)
      masterData()
    }
  }

  useEffect(() => {
    if (selectFeeType?.id) {
      if (selectFeeType?.id === "upfront") {
        setStartDate("")
        setEndDate("")
        masterData()
      } else if (selectFeeType?.id === null) {
        setData([])
      }
    }
  }, [
    selectFeeType,
    selectedProject,
    selectedDistributor,
    selectedRm,
    selectedStfStatusFilter,
    addendumStatusFilter,
    invoiceStatusFilter,
  ])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const getUrl = `/distributor-fees?${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = DistributorReportColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(
          el => (el.outstandingPrincipal = el.faceValue - el.redeemedFaceValue)
        )
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleValidOrderSubmit = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.patch(
        "user-investment-topup/" + selectedRow.topupId,
        {
          is_upfront_fee_paid: true,
        }
      )
      if (res) {
        toast.success("Upfront Fee marked as paid!")
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      toggle()
      setLoading(false)
      masterData()
    }
  }

  const getFinancialYears = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear()
    const endYear = new Date(endDate).getFullYear()
    const startMonth = new Date(startDate).getMonth() + 1

    const financialYears = []
    if (startMonth >= 1 && startMonth <= 3) {
      financialYears.push({
        startDate: `${startYear - 1}-04-01`,
        endDate: `${startYear}-03-31`,
        id: `${startYear - 1}-${startYear}`,
      })
    }

    for (let year = startYear; year <= endYear; year++) {
      const financialYearStartDate = `${year}-04-01`
      const financialYearEndDate = `${year + 1}-03-31`

      // Check if the financial year lies within the given range
      if (
        financialYearStartDate <= endDate &&
        financialYearEndDate >= startDate
      ) {
        financialYears.push({
          startDate: financialYearStartDate,
          endDate: financialYearEndDate,
          id: `${year}-${year + 1}`,
        })
      }
    }

    return financialYears
  }

  const getInvestmentlots = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(
        `/investment-lot?investmentId=${selectedProject.id}&$sort[disbursement_date]=1`
      )
      if (res) {
        const startDate = res.data[0].disbursement_date
        const endDate = selectedProject.maturity_date
        const financialYears = getFinancialYears(startDate, endDate)
        setFinancialYearFilters(financialYears)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (selectFeeType?.id == "upfront" && selectedProject) {
  //     getInvestmentlots()
  //   }
  // }, [selectedProject])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="DISTRIBUTOR REPORT" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                {selectFeeType?.id ? (
                  <div className="col-auto">
                    {(accessRestriction >= "2" ||
                      accessRestriction === "SuperAdmin") && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => {
                          downloadData("Distributor Report")
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download
                      </Button>
                    )}
                  </div>
                ) : null}
              </Row>
              <Card>
                <CardBody>
                  <Row className="mb-2 row">
                    <Col md={3}>
                      <div className="mb-3">
                        <label>Fee Type</label>
                        <ReactSelect
                          users={StatusObjForDistributorReport}
                          setSelectedOption={e =>
                            handleFilterChange(e, "feeType")
                          }
                          multiOptionLabel={true}
                          optionLabelKeys={["statusText"]}
                          isClearable={true}
                        />
                      </div>
                    </Col>
                    {selectFeeType?.id ? (
                      <>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Project Name</label>
                            <ReactSelect
                              users={investments}
                              selectedOption={selectedProject}
                              setSelectedOption={setSelectedProject}
                              multiOptionLabel={true}
                              optionLabelKeys={["project_name"]}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>Distributor Name</label>
                          <ReactSelect
                            users={distributorUsers}
                            // searchValue={distributorSearch}
                            // setSearchValue={handleDistributorSearch}
                            selectedOption={selectedDistributor}
                            setSelectedOption={setSelectedDistributor}
                            multiOptionLabel={true}
                            optionLabelKeys={["user_name", "email", "phone"]}
                            isClearable={true}
                          />
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Relationship Manager</label>
                            <ReactSelect
                              users={relManagerUsers}
                              selectedOption={selectedRm}
                              setSelectedOption={setSelectedRm}
                              multiOptionLabel={true}
                              optionLabelKeys={["user_name", "email", "phone"]}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selectFeeType?.id == "upfront" ? (
                      <>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Financial Year</label>
                            <ReactSelect
                              users={generateFinancialYearList()}
                              setSelectedOption={setSelectedFinacialYearFilter}
                              multiOptionLabel={true}
                              optionLabelKeys={["id"]}
                              isClearable={true}
                              // isDisabled={!selectedProject}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="col-auto">
                            <label>Search Investor</label>
                            <input
                              onChange={e => handleFilterChange(e, "user")}
                              id="search-bar-0"
                              type="text"
                              className="form-control rounded custom-input-height"
                              placeholder={`Search Name, Email, Phone`}
                              value={userFilter || ""}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>STF Status</label>
                            <ReactSelect
                              users={stfStatusFilterList}
                              selectedOption={selectedStfStatusFilter}
                              setSelectedOption={setSelectedStfStatusFilter}
                              multiOptionLabel={true}
                              optionLabelKeys={["text"]}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Addendum Status</label>
                            <ReactSelect
                              users={addendumSignedFilterList}
                              selectedOption={addendumStatusFilter}
                              setSelectedOption={setAddendumStatusFilter}
                              multiOptionLabel={true}
                              optionLabelKeys={["text"]}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label>Invoice Status</label>
                            <ReactSelect
                              users={distributorInvoiceReportStatus}
                              selectedOption={invoiceStatusFilter}
                              setSelectedOption={setInvoiceStatusFilter}
                              multiOptionLabel={true}
                              optionLabelKeys={["statusText"]}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selectFeeType?.id === "trail" ? (
                      <Row>
                        <Col md={3}>
                          <div className="mb-4">
                            <Label>Start Date</Label>
                            <Input
                              name="date"
                              type="date"
                              value={startDate}
                              onChange={handleStartDate}
                              className="custom-input-height"
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-4">
                            <Label>End Date</Label>
                            <Input
                              name="date"
                              type="date"
                              value={endDate}
                              onChange={handleEndDate}
                              className="custom-input-height"
                            />
                          </div>
                        </Col>
                        <Col md={3} className="align-self-center">
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Row>
                  {showTrailData || selectFeeType?.id === "upfront" ? (
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={DistributorReportColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={DistributorReportColumns()}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />

                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  Mark as Paid?
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form className="mb-3" tag="h6">
                                      Are you sure you want to mark this fee as
                                      paid?
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <Button
                                            type="submit"
                                            color="success"
                                            className="save-user"
                                          >
                                            Confirm
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}${
                                finalSelectedRows.length
                                  ? ` | Selected rows: ${finalSelectedRows.length}`
                                  : ""
                              }
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  ) : (
                    <Row>
                      <Col lg="12">
                        <div className="text-center">
                          <Row className="justify-content-center">
                            <Col sm="4">
                              <div className="maintenance-img">
                                <img
                                  src={nodata}
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                          <h4 className="mt-5">Please select Fee Type</h4>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

distributorReport.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(distributorReport)
