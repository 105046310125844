import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { add, isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  usersData,
  investmentData,
  csvDownloadData,
  distributorUsers,
  getTimestamp,
  getActiveInvestmentLot,
  getInvestmentLotByProject,
  userInvestmentEmailTemplate,
  handleValidDate,
} from "constants/common"
import {
  AccessId,
  StatusObjForUserInvestment,
  pdfTemplates,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { humanize } from "constants/common"
import { StatusForUserInvestment } from "constants/ConstantFields"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"
import getBase64 from "base64"

const userInvestmentTopUp = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [investments, setInvestments] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [isEditTopupStatus, setIsEditTopupStatus] = useState(false)
  const [topupStatus, setTopupStatus] = useState("")

  const [stopEmail, setStopEmail] = useState(true)
  const [selectedProject, setSelectedProject] = useState({})
  const [lotSize, setLotSize] = useState()
  const [commitmentAmount, setCommitmentAmount] = useState()
  const [tcsAmount, setTcsAmount] = useState()
  const [tcsModal, setTcsModal] = useState(false)

  const [distributor, setDistributor] = useState([])
  const [selectedDistributor, setSelectedDistributor] = useState({})
  const [manageDistributorModal, setManageDistributorModal] = useState(false)
  const [updateAddendumModal, setUpdateAddendumModal] = useState(false)
  const [breakupData, setBreakupData] = useState()
  const [investmentDate, setInvestmentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const maxDate = moment().add(5, "days").format("YYYY-MM-DD")

  const [premium, setPremium] = useState()
  const [adjustment, setAdjustment] = useState()

  const [userFilter, setUserFilter] = useState("")
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [selectInvStage, setSelectInvStage] = useState({})
  const [investmentList, setInvestmentList] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [investmentLots, setInvestmentLots] = useState([])
  const [selectedInvestmentLotId, setSelectedInvestmentLotId] = useState()

  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [isSendEmail, setIsSendEmail] = useState(false)

  const [upload, setUpload] = useState(false)
  const [file, setFile] = useState(null)
  const [uploadModal, setUploadModal] = useState(false)
  const [base64, setBase64] = useState("")
  const [errorData, setErrorData] = useState([])
  const [errorModal, setErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [selectedAddendumData, setSelectedAddendumData] = useState(null)
  const [addendumData, setAddendumData] = useState([])
  const [trancheList, setTrancheList] = useState([])
  const [selectedTranche, setSelectedTranche] = useState(null)

  const [pdfModal, setPdfModal] = useState(false)
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState("")

  const [debentureTrusteeList, setDebentureTrusteeList] = useState([])
  const [selectedDebentureTrustee, setSelectedDebentureTrustee] = useState(null)
  const [transferorMasterList, setTransferorMasterList] = useState([])
  const [selectedTransferorMaster, setSelectedTransferorMaster] = useState(null)

  const [downloadErrorModal, setDownloadErrorModal] = useState(false)

  const [distributorList, setDistributorList] = useState([])
  const [selectedDistrinutor, setSelectedDistrinutor] = useState(null)

  const [rmList, setRmList] = useState([])
  const [selectedRm, setSelectedRm] = useState(null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${selectInvStage?.id ? `&inv_stage=${selectInvStage.id}` : ""}${
    investmentFilter?.id && selectedTranche?.id
      ? `&investmentLotId=${selectedTranche?.id}`
      : ""
  }${selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""}${
    selectedRm ? `&relManagerId=${selectedRm.id}` : ""
  }${startDate ? `&start_date=${startDate}` : ""}${
    endDate ? `&end_date=${endDate}` : ""
  }`

  const getUrl = `admin-user-investment-listing?topup=true&$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setData(
          data.map(item => ({
            ...item,
            trail_fee_applicable_date: calculateTrailFeeApplicableDate(
              item.created_at,
              item.trail_fee_applicable_days
            ),
          }))
        )
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const investmentGetUrl = `investment?$sort[created_at]=-1`
      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
            &name=${e}&$limit=50&$skip=0&is_registered=true`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const getFiltersDataList = async () => {
    try {
      setLoading(true)

      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      if (opsUsersRes) {
        const opsUsers = opsUsersRes.data.data
        setDistributorList(
          opsUsers?.filter(user => {
            if (user?.role?.department === "Distributor") {
              user.user_name = user.distributor_kyc?.name
              return true
            }
          })
        )
        setRmList(opsUsers?.filter(ele => ele.roleId == 1))
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getFiltersDataList()
  }, [])
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const handleMultipleIds = async str => {
    if (!finalSelectedRows.length) {
      toast.error("Please select atleast a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    setUpdateAddendumModal(false)
    setManageDistributorModal(false)
    switch (str) {
      case "manage_distributor":
        setLoading(true)
        setSelectedDistributor(null)
        setSelectedAddendumData(null)
        setAddendumData([])
        const distributorRes = await distributorUsers()
        if (distributorRes) {
          const distributorFromUser = distributorRes?.data?.data.filter(
            obj => obj.role?.department === "Distributor"
          )
          setDistributor(distributorFromUser)
        } else {
          toast.error("something went wrong")
        }
        setLoading(false)
        toggleManageDistributor()
        break

      case "update_addendum":
        if (!finalSelectedRows[0].distributorId) {
          toast.error("No Distributor assigned")
          break
        }
        setLoading(true)
        setSelectedDistributor(null)
        setSelectedAddendumData(null)
        setAddendumData([])
        const distRes = await distributorUsers()
        if (distRes) {
          const distributorFromUser = distRes?.data?.data.filter(
            obj => obj.role?.department === "Distributor"
          )
          setDistributor(distributorFromUser)
        } else {
          toast.error("something went wrong")
        }
        setLoading(false)
        toggleUpdateAddendum()
        break
      case "send_email":
        setIsSendEmail(true)
        setIsEditTopupStatus(false)
        setIsEdit(false)
        toggle()
        break
      case "generate_pdf":
        setSelectedPdfTemplate("")
        togglePdfModal()
        break
      case "set_topup_status":
        setIsEditTopupStatus(true)
        setIsSendEmail(false)
        setIsEdit(false)
      default:
        toggle()
        break
    }
  }

  //pagination customization

  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const UserInvestmentColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {(accessRestriction >= 3 || accessRestriction === "SuperAdmin") &&
            order.topup_status !== "MERGED" &&
            order.topup_status !== "CANCELLED" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "created_at",
      text: "Investment Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot",
      sort: true,
    },
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
    {
      dataField: "name_on_pan",
      text: "Name on Pan",
      sort: true,
    },
    {
      dataField: "pan_number",
      text: "PAN Number",
      sort: true,
    },
    {
      dataField: "demat_id",
      text: "Demat Id",
      sort: true,
    },
    {
      dataField: "dp_name",
      text: "DP Name",
      sort: true,
      formatter: (cellContent, row) =>
        Array.isArray(row.depository_master)
          ? row.depository_master.length
            ? row.depository_master[0].dp_name
            : ""
          : "",
    },
    {
      dataField: "inv_stage",
      text: "Investment Stage",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = StatusObjForUserInvestment.find(
          obj => obj.id == cellContent
        )
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText || cellContent}
          </span>
        )
      },
    },
    {
      dataField: "topup_status",
      text: "Topup Status",
      sort: true,
    },
    {
      dataField: "lot_size",
      text: "Bonds",
      sort: true,
    },
    {
      dataField: "pendings.lot_price",
      text: "Current Bond Price",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.pendings.lot_price),
    },
    {
      dataField: "amount",
      text: "Initial Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(Math.floor(row.amount)),
    },
    {
      dataField: "pendings.current_tranche_all",
      text: "Called Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        handleAmount(row.pendings.current_tranche_all),
    },
    {
      dataField: "pendings.amountPaid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row.inv_stage === "Completed" && row.topup_status === "MERGED") {
          return handleAmount(row.pendings.current_tranche_all)
        } else {
          return handleAmount(row.pendings.amountPaid)
        }
      },
    },
    {
      dataField: "pendings.payable_amount",
      text: "Payable Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row.inv_stage === "Completed" && row.topup_status === "MERGED") {
          return 0
        } else {
          return handleAmount(row.pendings.payable_amount)
        }
      },
    },
    {
      dataField: "tcs_amount",
      text: "TCS Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.tcs_amount),
    },
    {
      dataField: "face_value",
      text: "Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.face_value)}</div>
      ),
    },
    {
      dataField: "called_face_value",
      text: "Called Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.called_face_value)}</div>
      ),
    },
    {
      dataField: "redeemed_face_value",
      text: "Redeemed Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.redeemed_face_value)}</div>
      ),
    },
    {
      dataField: "accrued_interest",
      text: "Accrued Interest",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.accrued_interest)}</div>
      ),
    },
    {
      dataField: "premium",
      text: "Premium",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <div>{handleAmount(row.premium)}</div>,
    },
    {
      dataField: "adjustment",
      text: "Adjustment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.adjustment)}</div>
      ),
    },
    {
      dataField: "nil_tds_applicable",
      text: "Nil TDS Applicable",
      sort: true,
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
    },
    {
      dataField: "annual_return",
      text: "Annual Return",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{cellContent ? cellContent + "%" : ""}</div>
      ),
    },
    {
      dataField: "pendings.token_amount_applicable",
      text: "Token Amount Applicable",
      sort: true,
    },

    {
      dataField: "pendings.token_amount",
      text: "Token Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.pendings.token_amount),
    },

    {
      dataField: "is_token_amount_received",
      text: "Token Amount Received",
      sort: true,
    },
    {
      dataField: "is_balance_amount_received",
      text: "Balance Amount Received",
      sort: true,
    },
    {
      dataField: "transferor_name",
      text: "Transferor Name",
      sort: true,
    },
    {
      dataField: "distributorName",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "distributor_legal_entity_name",
      text: "Distributor Legal Entity Name",
      sort: true,
    },
    {
      dataField: "distributorEmail",
      text: "Distributor Email",
      sort: true,
    },
    {
      dataField: "addendum_no",
      text: "Addendum No.",
      sort: true,
    },
    {
      dataField: "upfront_fee_percent",
      text: "Distributor Upfront Fee Percent",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{cellContent ? Number(cellContent).toFixed(2) : "0"}</div>
      ),
    },
    {
      dataField: "additional_fee_percent",
      text: "Trail Fee Percent",
      sort: true,
    },
    {
      dataField: "additional_fee_payment_frequency",
      text: "Trail Fee Payment Frequency",
      sort: true,
    },
    {
      dataField: "trail_fee_applicable_date",
      text: "Trail Fee Applicable Date",
      sort: true,
    },
    {
      dataField: "rm_user_name",
      text: "RM Name",
      sort: true,
    },
    {
      dataField: "rm_email",
      text: "RM Email",
      sort: true,
    },
    {
      dataField: "rm_phone",
      text: "RM Phone",
      sort: true,
    },
    {
      dataField: "distributorAddendumId",
      text: "Distributor Addendum Id",
      sort: true,
    },
    {
      dataField: "id",
      text: "Topup User Investment Id",
      sort: true,
    },
    {
      dataField: "userInvestmentId",
      text: "User Investment Id",
      sort: true,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "refByUser.user_name",
      text: "Referred By",
      sort: true,
    },
    {
      dataField: "refByUser.phone",
      text: "Referee's Phone",
      sort: true,
    },
    {
      dataField: "refByUser.email",
      text: "Referee's Email",
      sort: true,
    },
    {
      dataField: "refByUser.self_ref_code",
      text: "Ref Code",
      sort: true,
    },
    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]
  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const toggleManageDistributor = () => {
    setManageDistributorModal(!manageDistributorModal)
  }
  const toggleUpdateAddendum = () => {
    setUpdateAddendumModal(!updateAddendumModal)
  }

  const togglePdfModal = () => {
    setPdfModal(!pdfModal)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const calculateTrailFeeApplicableDate = (created_at, trailFeeDays) => {
    if (trailFeeDays == null) return null

    created_at = moment(created_at).add(trailFeeDays, "days").toDate()
    const date = moment(created_at).format("DD MMM Y hh:mm a")

    return date
  }

  const handleOrderClick = async arg => {
    setIsEdit(true)
    setIsEditTopupStatus(false)
    setUpload(false)
    setBase64("")
    const order = arg
    setLoading(true)

    const selectedUser = await axiosInstance.get(`/users/${order.userId}`)
    setLoading(false)

    setSelectedOption(selectedUser.data)
    const currentInv = {
      id: order.investmentId,
      project_name: order.project_name,
    }

    setSelectedProject(currentInv)
    setSelectedDistributor({})
    setStopEmail(true)
    setLotSize(order.lot_size || order.min_lot)
    setInvestmentDate(moment(new Date(order.created_at)).format("YYYY-MM-DD"))
    setBreakupData({
      face_value: order?.face_value || 0,
      called_face_value: order?.called_face_value || 0,
      redeemed_face_value: order?.redeemed_face_value || 0,
      accrued_interest: order?.accrued_interest || 0,
      annual_return: order?.annual_return || 0,
    })
    setPremium(order?.premium || 0)
    setAdjustment(order?.adjustment || 0)
    setCommitmentAmount(Math.floor(order.amount))
    setTcsAmount(Math.ceil(order.tcs_amount))
    setOrderList({
      id: order.id,
      amount: order.amount,
      currency: order.currency,
      email: order.email,
      inv_stage: order.inv_stage,
      project_name: order.investmentId,
      is_balance_amount_received: order.is_balance_amount_received,
      is_token_amount_received: order.is_token_amount_received,
      lot_size: order.lot_size,
      phone: order.phone,
      userId: order.userId,
      name: order.user_name,
      name_on_pan: order.name_on_pan,
      pan_number: order.pan_number,
      distributorId: order.distributorId,
      distributorName: order.distributorName,
      distributorEmail: order.distributorEmail,
      distributorPhone: order.distributorPhone,
      distributor_upfront_fee_percent: order.distributor_upfront_fee_percent,
      trail_fee_percent: order.trail_fee_percent,
      trail_fee_payment_frequency: order.trail_fee_payment_frequency,
      trail_fee_applicable_date: order.trail_fee_applicable_date,
    })
    setIsSendEmail(false)
    toggle()
  }

  useEffect(() => {
    if (
      (manageDistributorModal || updateAddendumModal) &&
      finalSelectedRows.length &&
      distributor.length
    ) {
      const distributorId = finalSelectedRows[0].distributorId
      if (distributorId) {
        const dist = distributor.find(obj => obj.id == distributorId)
        handleSelectedDistributor(dist)
      }
    }
  }, [manageDistributorModal, distributor, updateAddendumModal])

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (pdfModal) {
      if (!selectedPdfTemplate.id) {
        setLoading(false)
        toast.error("Select a template")
        return
      }
      try {
        const payload = {
          type: selectedPdfTemplate.id,
          userInvestmentTopupId: finalSelectedRows[0].id,
          date_of_execution: values.date_of_execution,
          debenture_trustee_name:
            selectedDebentureTrustee.debenture_trustee_name,
          debenture_trustee_cin: selectedDebentureTrustee.debenture_trustee_cin,
          transferor_name: selectedTransferorMaster.transferor_name,
          transferor_depository:
            selectedTransferorMaster.transferor_depository_name,
          transferor_dp_name: selectedTransferorMaster.dp_name,
          transferor_dp_id: selectedTransferorMaster.dp_id,
          transferor_client_id: selectedTransferorMaster.client_id,
          transferee_designation: values.transferee_designation,
          date_of_execution: values.execution_date,
          settlement_days: values.settlement_days,
          trade_date: values.trade_date,
        }
        console.log({ selectedTransferorMaster })
        const response = await axiosInstance.post("/generate-pdf", payload, {
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            const totalLength = progressEvent.total
            const downloadedLength = progressEvent.loaded
            const progressPercentage = (downloadedLength / totalLength) * 100
          },
        })
        const contentDispositionHeader = response.headers["content-disposition"]
        const match = contentDispositionHeader.match(/filename="(.+?)"/)
        const filename = match ? match[1] : "download.pdf"
        const blob = new Blob([response.data], {
          type: "application/pdf",
        })
        const downloadLink = document.createElement("a")
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = filename
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        togglePdfModal()
      } catch (error) {
        toast.error("Error while downloading PDF")
      }
      setLoading(false)
    } else if (isEditTopupStatus) {
      const obj = {
        topup_status: topupStatus,
      }

      for (let i = 0; i < finalSelectedRows.length; i++) {
        try {
          const response = await axiosInstance.patch(
            `user-investment-topup/${finalSelectedRows[i].id}`,
            obj
          )
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
          break
        }
      }
      toast.success("Successfully Edited")
      masterData()
      toggle()
    } else if (isEdit) {
      const updateOrder = {
        userId: selectedOption.id || orderList?.userId,
        investmentId: values.project_name,
        lot_size: values.lot_size,
        inv_stage: values.inv_stage,
        amount: parseFloat(values.amount) || 0,
        tcs_amount: parseFloat(tcsAmount) || 0,
        face_value: parseFloat(breakupData?.face_value),
        called_face_value: parseFloat(breakupData?.called_face_value),
        redeemed_face_value: parseFloat(breakupData?.redeemed_face_value),
        accrued_interest: parseFloat(breakupData?.accrued_interest),
        premium: parseFloat(premium),
        adjustment: parseFloat(adjustment),
        annual_return: parseFloat(breakupData?.annual_return),
        created_at: getTimestamp(investmentDate),
        investmentLotId: selectedInvestmentLotId,
      }
      // update order
      const dontSendEmail = stopEmail || values.inv_stage == "Commitment"

      try {
        const response = await axiosInstance.patch(
          `user-investment-topup/${orderList.id}${
            dontSendEmail ? "?send_email=false" : ""
          }`,
          updateOrder
        )
        if (
          response &&
          (response.data.inv_stage === "Commitment" ||
            response.data.inv_stage === "Initiate_Balance_Payment" ||
            response.data.inv_stage === "Awaiting_Manual_Transfer" ||
            response.data.inv_stage === "Awaiting_Token_Manual_Transfer")
        ) {
          await createTransaction(response.data)
        }
        masterData()
        toast.success("Successfully Edited")
        toggle()
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(humanize(msg))
      }
    } else if (isSendEmail) {
      try {
        const mailObj = {
          template_id: parseInt(values.templateId),
          topup: true,
          data: finalSelectedRows.map(item => ({
            userId: item.userId,
            userInvestmentId: item.id,
          })),
        }
        const response = await axiosInstance.post(`send-email`, mailObj)
        if (response) {
          setFinalSelectedRows([])
          toast.success("Email Sent Successfully.")
        }
      } catch (error) {
        console.log({ error })
        toast.error(error)
      }
      toggle()
    } else if (manageDistributorModal) {
      if (!selectedDistributor?.id) {
        toast.error("please select a distributor")
        setLoading(false)
        return false
      }
      if (addendumData.length && !selectedAddendumData) {
        toast.error("please select addendum no.")
        setLoading(false)
        return
      }
      const newDistributor = {
        distributorId:
          selectedDistributor.id === "null" ? null : selectedDistributor.id,
        distributorAddendumId:
          selectedDistributor.id === "null" || !selectedAddendumData?.id
            ? null
            : selectedAddendumData.id,
      }
      for (let i = 0; i < finalSelectedRows.length; i++) {
        try {
          const response = await axiosInstance.patch(
            `user-investment-topup/${finalSelectedRows[i].id}`,
            newDistributor
          )
          if (response) {
            toast.success("Successfully Edited")
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(humanize(msg))
        }
      }
      masterData()
      toggleManageDistributor()
    } else if (updateAddendumModal) {
      if (addendumData.length && !selectedAddendumData) {
        toast.error("please select addendum no.")
        setLoading(false)
        return
      }
      const newDistributor = {
        distributorAddendumId:
          selectedDistributor.id === "null" || !selectedAddendumData?.id
            ? null
            : selectedAddendumData.id,
      }
      for (let i = 0; i < finalSelectedRows.length; i++) {
        try {
          const response = await axiosInstance.patch(
            `user-investment-topup/${finalSelectedRows[i].id}`,
            newDistributor
          )
          if (response) {
            toast.success("Successfully Edited")
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(humanize(msg))
        }
      }
      masterData()
      toggleUpdateAddendum()
    } else if (upload) {
      const dataToUpload = {
        ui_topups: base64.replace("data:text/csv;base64,", ""),
      }
      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/upload-base-64?type=user_investment_topup",
            dataToUpload
          )
          if (uploadResponse) {
            masterData()
            toast.success("Sucessfully Uploaded")
            setUpload(false)
            setBase64("")
            toggleUploadModal()
          }
        } catch (error) {
          setErrorData(error?.response?.data?.data)
          const msg = error?.response?.data.message
          setErrorMsg(msg)
          setErrorModal(true)
          toast.error("Something went wrong!")
        }
      } else {
        showToastError("Please select valid file")
        setLoading(false)
        return false
      }
    } else {
      const newOrder = {
        userId: selectedOption.id,
        investmentId: values.project_name,
        lot_size: values.lot_size,
        inv_stage: values.inv_stage,
        amount: parseFloat(values.amount) || 0,
        distributorId: selectedOption.createdById,
        tcs_amount: parseFloat(tcsAmount) || 0,
        face_value: parseFloat(breakupData?.face_value),
        called_face_value: parseFloat(breakupData?.called_face_value),
        redeemed_face_value: parseFloat(breakupData?.redeemed_face_value),
        accrued_interest: parseFloat(breakupData?.accrued_interest),
        premium: parseFloat(premium),
        adjustment: parseFloat(adjustment),
        annual_return: parseFloat(breakupData?.annual_return),
        created_at: getTimestamp(investmentDate),
        investmentLotId: selectedInvestmentLotId,
      }
      // save new order
      const dontSendEmail = stopEmail || values.inv_stage == "Commitment"

      try {
        const response = await axiosInstance.post(
          `user-investment-topup${dontSendEmail ? "?send_email=false" : ""}`,
          newOrder
        )
        if (
          response &&
          (response.data.inv_stage === "Commitment" ||
            response.data.inv_stage === "Initiate_Balance_Payment" ||
            response.data.inv_stage === "Awaiting_Manual_Transfer" ||
            response.data.inv_stage === "Awaiting_Token_Manual_Transfer")
        ) {
          await createTransaction(response.data)
        }
        masterData()
        toast.success("Successfully Added")
        toggle()
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(humanize(msg))
      }
    }
    setLoading(false)
    setFinalSelectedRows([])
  }
  const createTransaction = async userInvRes => {
    const transactionData = {
      transaction_type: "Direct",
      payment_type: "Bank_Transfer",
      userInvestmentTopupId: userInvRes.id,
      transaction_amount: userInvRes.pendings.payable_amount * 100,
      payment_status: "initiated",
      userId: userInvRes.userId,
      created_at: userInvRes.created_at,
    }
    const res = await axiosInstance.post(`transaction-topup`, transactionData)
    if (userInvRes.tcs_amount !== 0) {
      const tcsData = {
        userInvestmentTopupId: userInvRes.id,
        userId: userInvRes.userId,
        transaction_amount: userInvRes.tcs_amount * 100,
        payment_status: "initiated",
        payment_type: "Bank_Transfer",
        created_at: userInvRes.created_at,
      }

      const response = await axiosInstance.post(
        `tcs-transaction-topup`,
        tcsData
      )
    }
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const investmentCalc = async (
    lots = lotSize,
    date = investmentDate,
    adjustmentAmount = adjustment
  ) => {
    setLoading(true)
    const getUrl = `investment-calc-new/${
      selectedProject?.id
    }?multr=${lots}&userId=${selectedOption?.id}&topup=true&date=${date}${
      adjustmentAmount ? `&adjustment=${adjustmentAmount}` : ""
    }`
    try {
      const tcsAmountRes = await axiosInstance.get(getUrl)
      if (tcsAmountRes) {
        setCommitmentAmount(Math.floor(tcsAmountRes?.data?.base_amount))
        setTcsAmount(Math.ceil(tcsAmountRes?.data?.tcs_amount))
        setBreakupData({
          face_value: tcsAmountRes?.data?.face_value * Number(lots),
          called_face_value:
            tcsAmountRes?.data?.called_face_value * Number(lots),
          redeemed_face_value:
            tcsAmountRes?.data?.redeemed_face_value * Number(lots),
          accrued_interest: tcsAmountRes?.data?.accrued_interest * Number(lots),
          annual_return: tcsAmountRes?.data?.annual_return,
        })
        setPremium(tcsAmountRes?.data?.premium * Number(lots))
        // setAdjustment(0)
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const handleProjectName = e => {
    const currentInv = investments.find(val => val.id == e.target.value)
    setSelectedProject(currentInv)
    let event = {
      target: {
        value: currentInv.min_lot,
      },
    }
    handleLotSize(event)
  }
  const handleLotSize = async e => {
    let lotSize = parseInt(e.target.value)
    setLotSize(lotSize)
    if (
      isEdit &&
      selectedProject?.id &&
      selectedOption?.id &&
      lotSize &&
      investmentDate
    ) {
      investmentCalc(lotSize)
    }
  }

  const handleInvestmentDate = e => {
    const date = e.target.value
    setInvestmentDate(date)
    if (
      isEdit &&
      selectedProject?.id &&
      selectedOption?.id &&
      lotSize &&
      date
    ) {
      investmentCalc(lotSize, date)
    }
  }

  useEffect(async () => {
    if (
      !isEdit &&
      selectedProject?.id &&
      selectedOption?.id &&
      lotSize &&
      investmentDate
    ) {
      investmentCalc()
    }
  }, [selectedProject, selectedOption, lotSize, investmentDate])

  useEffect(async () => {
    if (selectedProject?.id) {
      setLoading(true)
      const invLots = await getInvestmentLotByProject(selectedProject.id)
      setSelectedInvestmentLotId(invLots.find(val => val.status)?.id)
      setInvestmentLots(invLots)
      setLoading(false)
    }
  }, [selectedProject])

  useEffect(async () => {
    if (modal) {
      setUsers([])
      const investmentRes = await investmentData(isEdit ? "" : "Approved")
      const investments = investmentRes?.data?.data || investmentRes?.data

      setInvestments(investments)
    } else {
      setIsEditTopupStatus(false)
      setSelectedOption({})
      setSelectedDistributor({})
      setOrderList("")
      setStopEmail(true)
      setSelectedProject({})
      setLotSize()
      setInvestmentDate(moment(new Date()).format("YYYY-MM-DD"))
      setCommitmentAmount()
      setTcsAmount()
      setBreakupData()
      setSelectedInvestmentLotId()
      setInvestmentLots([])
      setIsEdit(false)
      setIsSendEmail(false)
      setUpload(false)
      setBase64("")
      setAdjustment(0)
    }
  }, [modal])

  // useEffect(() => {
  //   if (breakupData) {
  //     const { face_value, redeemed_face_value, accrued_interest } = breakupData
  //     if (adjustment) {
  //     }
  //     setCommitmentAmount(
  //       parseFloat(face_value) -
  //         parseFloat(redeemed_face_value) +
  //         parseFloat(accrued_interest) +
  //         parseFloat(premium ? premium : 0)
  //     )
  //   }
  // }, [premium, adjustment])

  const onAdjustmentChange = async e => {
    const value = e.target.value
    setAdjustment(value)
    await investmentCalc(lotSize, investmentDate, value)
  }

  useEffect(async () => {
    if (selectedPdfTemplate) {
      setLoading(true)
      try {
        const debentureTrusteeRes = await axiosInstance.get(`debenture-trustee`)
        setDebentureTrusteeList(
          debentureTrusteeRes?.data?.data || debentureTrusteeRes?.data
        )

        const transferorMasterRes = await axiosInstance.get(`transferor-master`)
        setTransferorMasterList(
          transferorMasterRes?.data?.data || transferorMasterRes?.data
        )
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [selectedPdfTemplate])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  useEffect(() => {
    masterData()
  }, [
    limit,
    skip,
    selectInvStage,
    investmentFilter,
    selectedTranche,
    selectedDistrinutor,
    selectedRm,
    startDate,
    endDate,
  ])
  useEffect(async () => {
    setSelectedTranche(null)
    if (investmentFilter?.id) {
      const invLots = await getInvestmentLotByProject(investmentFilter?.id)
      setTrancheList(invLots)
    } else {
      setTrancheList([])
    }
  }, [investmentFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "invLots":
        setSelectedTranche(e)
        break
      case "invStage":
        setSelectInvStage(e)
        break
      case "distributor":
        setSelectedDistrinutor(e)
        break
      case "rm":
        setSelectedRm(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const handleUploadExcel = e => {
    setUpload(true)
    toggleUploadModal()
    setErrorData([])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Topup,${projectName}`, true)
  }

  const downloadExcel = async (id, lots, date) => {
    if (
      total > 500
      // && !userFilter.length
      // && !investmentFilter?.id
      // && !selectInvStage?.id
    ) {
      setDownloadErrorModal(true)
      return
    }
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        selectInvStage?.id ? `&inv_stage=${selectInvStage.id}` : ""
      }${
        investmentFilter?.id && selectedTranche?.id
          ? `&investmentLotId=${selectedTranche?.id}`
          : ""
      }${selectedRm ? `&relManagerId=${selectedRm.id}` : ""}${
        startDate ? `&start_date=${startDate}` : ""
      }${endDate ? `&end_date=${endDate}` : ""}`
      const downloadUrl = `/download-excel?type=user-investment-topup&topup=true&$sort[created_at]=-1${filterUrl}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const sampleCsv = [
    {
      userId: "97166200-4e7d-4d7b-b4b7-2b318a429192",
      investmentId: "fcd4051a-81a7-41de-9d57-1ddd26f3c0e2",
      lot_size: 10,
      inv_stage: "Commitment",
      amount: 1000000,
      tcs_amount: 100,
      face_value: 1000000,
      called_face_value: 1000000,
      redeemed_face_value: 0,
      accrued_interest: 0,
      premium: 0,
      adjustment: 0,
      annual_return: 0,
      created_at: "2022-08-01 12:00:00+05:30",
    },
  ]

  const handleSelectedDistributor = async dist => {
    try {
      setSelectedDistributor(dist)
      setLoading(true)
      setSelectedAddendumData(null)
      setAddendumData([])
      if (dist.id == "null") {
        return
      }
      const addendumResponse = await axiosInstance.get(
        `/distributor-addendum?distributorId=${dist.id}&investmentId=${finalSelectedRows[0].investmentId}`
      )
      if (addendumResponse) {
        const data = addendumResponse.data?.data || addendumResponse.data
        if (!data.length) {
          toast.warn("No addendum available for this distributor")
        }
        setAddendumData(
          data.map(item => ({
            ...item,
            addendum_no: item.status
              ? item.addendum_no + " (Active)"
              : item.addendum_no,
          }))
        )
        if (finalSelectedRows[0]?.distributorAddendumId) {
          const addendum = data.find(
            obj => obj.id == finalSelectedRows[0]?.distributorAddendumId
          )
          if (addendum) {
            setSelectedAddendumData(addendum)
          }
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="User Investment Top Up" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <div className="col-auto">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={toggle}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {/* {accessRestriction >= 2 ||
                  accessRestriction === "SuperAdmin" ? (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleUploadExcel}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Upload
                    </Button>
                  ) : null} */}
                  {/* {accessRestriction >= 1 ||
                  accessRestriction === "SuperAdmin" ? (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() =>
                        handleCsvDownload(sampleCsv, "Sample", true)
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i> Sample
                      CSV
                    </Button>
                  ) : null} */}
                  {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("send_email")}
                      disabled={finalSelectedRows.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Send Email
                    </Button>
                  )}

                  {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("manage_distributor")}
                      disabled={finalSelectedRows?.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Manage Distributor
                    </Button>
                  )}
                  {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("update_addendum")}
                      disabled={finalSelectedRows?.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Update Addendum
                    </Button>
                  )}
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => handleMultipleIds("set_topup_status")}
                      disabled={
                        finalSelectedRows?.length != 1 ||
                        finalSelectedRows[0].topup_status === "MERGED"
                      }
                    >
                      <i className="mdi mdi-pencil me-1" /> Set Topup Status
                    </Button>
                  )}
                  {/* {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("generate_pdf")}
                      disabled={
                        finalSelectedRows?.length != 1 ||
                        finalSelectedRows[0].inv_stage !== "Completed"
                      }
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Generate PDF
                    </Button>
                  )} */}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => {
                        downloadExcel("Users Investment Top Up")
                      }}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download Excel
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={UserInvestmentColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Investment Lot</label>
                              <ReactSelect
                                users={[...trancheList]}
                                selectedOption={selectedTranche}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "invLots")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["investment_lot_name"]}
                                isClearable={true}
                                isDisabled={!investmentFilter?.id}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Investment Stage</label>
                              <ReactSelect
                                users={StatusObjForUserInvestment}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "invStage")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Distributor</label>
                              <ReactSelect
                                users={[
                                  {
                                    id: "null",
                                    user_name: "None",
                                    email: "",
                                    phone: "",
                                  },
                                  ...distributorList,
                                ]}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "distributor")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={[
                                  "user_name",
                                  "email",
                                  "phone",
                                ]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Relationship Manager</label>
                              <ReactSelect
                                users={rmList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "rm")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={[
                                  "user_name",
                                  "email",
                                  "phone",
                                ]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-4">
                              <Label>Investment Start Date</Label>
                              <Input
                                name="date"
                                type="date"
                                value={startDate}
                                onChange={e => {
                                  setStartDate(e.target.value)
                                }}
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-4">
                              <Label>Investment End Date</Label>
                              <Input
                                name="date"
                                type="date"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={UserInvestmentColumns(toggle)}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEditTopupStatus
                                  ? "Set Topup Status"
                                  : !!isEdit
                                  ? "Edit User Investment"
                                  : isSendEmail
                                  ? "Send Email"
                                  : "Add User Investment"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    {isSendEmail ? (
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="templateId"
                                            label="Select the type of email"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid email template"
                                            value={orderList.templateId || ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            style={{ width: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {userInvestmentEmailTemplate.map(
                                              (item, index) => (
                                                <option
                                                  key={item.templateName}
                                                  value={item.id}
                                                >
                                                  {item.templateName}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                      </Col>
                                    ) : isEditTopupStatus ? (
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="topup_status"
                                            label="Select Topup Status"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Topup Status"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={e => {
                                              setTopupStatus(e.target.value)
                                            }}
                                            style={{ width: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="CANCELLED">
                                              Cancel
                                            </option>
                                          </AvField>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col className="col-12">
                                        {!isEdit ? (
                                          <div className="mb-3">
                                            <label>User</label>
                                            <ReactSelect
                                              isDisabled={isEdit}
                                              users={users}
                                              searchValue={searchValue}
                                              setSearchValue={handleUserSearch}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        ) : (
                                          <div className="mb-3">
                                            <label>User</label>
                                            <ReactSelect
                                              isDisabled={isEdit}
                                              users={users}
                                              selectedOption={{
                                                user_pan: {
                                                  name: orderList.name_on_pan,
                                                  pan_number:
                                                    orderList.pan_number,
                                                },
                                                email: orderList.email,
                                                phone: orderList.phone,
                                              }}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        )}
                                        <div className="row">
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="project_name"
                                              label="Project Name"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={isEdit}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={selectedProject.id}
                                              onChange={handleProjectName}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {sortingByAlphabet(
                                                investments?.map(
                                                  (item, index) => (
                                                    <option
                                                      key={item?.project_name}
                                                      value={item?.id}
                                                    >
                                                      {item?.project_name}
                                                    </option>
                                                  )
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="created_at"
                                              label="Investment Date (IST)"
                                              type="date"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={investmentDate}
                                              max={maxDate}
                                              onChange={handleInvestmentDate}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="investmentLotId"
                                              label="Investment Lot"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={selectedInvestmentLotId}
                                              onChange={e => {
                                                setSelectedInvestmentLotId(
                                                  e.target.value
                                                )
                                              }}
                                              disabled={!selectedProject?.id}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {sortingByAlphabet(
                                                investmentLots?.map(
                                                  (item, index) => {
                                                    if (item.status)
                                                      return (
                                                        <option
                                                          key={item?.id}
                                                          value={item?.id}
                                                        >
                                                          {
                                                            item?.investment_lot_name
                                                          }
                                                        </option>
                                                      )
                                                  }
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="lot_size"
                                              label="Bonds"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={lotSize || 0}
                                              onChange={handleLotSize}
                                              pattern="^\d+$"
                                              step={1}
                                            ></AvField>
                                          </div>

                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="amount"
                                              label="Initial Commitment"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                commitmentAmount === 0
                                                  ? "0"
                                                  : commitmentAmount
                                              }
                                              onChange={e =>
                                                setCommitmentAmount(
                                                  e.target.value
                                                )
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>

                                          <div className="mb-3 col-md-6">
                                            <Modal
                                              isOpen={tcsModal}
                                              toggle={() => {
                                                setTcsModal(!tcsModal)
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setTcsModal(!tcsModal)
                                                }}
                                                tag="h3"
                                              >
                                                TCS
                                              </ModalHeader>
                                              <ModalBody>
                                                <p className="font-size-16">
                                                  0.1% TCS is applicable on
                                                  gross investments above 50
                                                  lacs in a financial year. The
                                                  same will be deposited against
                                                  investor’s PAN (like TDS) and
                                                  can be claimed in his/her tax
                                                  return.
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              TCS{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() => {
                                                  setTcsModal(true)
                                                }}
                                              ></i>
                                            </Label>
                                            <AvField
                                              name="tcsAmount"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={tcsAmount}
                                              onChange={e =>
                                                setTcsAmount(e.target.value)
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>

                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="face_value"
                                              label="Face Value"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.face_value === 0
                                                  ? "0"
                                                  : breakupData?.face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  face_value: e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="called_face_value"
                                              label="Called Face Value"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.called_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.called_face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  called_face_value:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="redeemed_face_value"
                                              label="Redeemed Face Value"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.redeemed_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.redeemed_face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  redeemed_face_value:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="accrued_interest"
                                              label="Accrued Interest"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.accrued_interest ===
                                                0
                                                  ? "0"
                                                  : breakupData?.accrued_interest
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  accrued_interest:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="premium"
                                              label="Premium"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                premium === 0 ? "0" : premium
                                              }
                                              onChange={e =>
                                                setPremium(e.target.value)
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="adjustment"
                                              label="Adjustment"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={
                                                adjustment == 0
                                                  ? "0"
                                                  : adjustment
                                              }
                                              onChange={onAdjustmentChange}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="inv_stage"
                                              label="Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort={true}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.inv_stage || ""}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {StatusForUserInvestment?.map(
                                                (item, index) => (
                                                  <option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {humanize(item)}
                                                  </option>
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="sendEmail"
                                            label="Do not send email"
                                            type="checkbox"
                                            value={stopEmail}
                                            onChange={e => {
                                              setStopEmail(e.target.checked)
                                            }}
                                          ></AvField>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          {isSendEmail ? "Send Email" : "Save"}
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={manageDistributorModal}
                              toggle={toggleManageDistributor}
                            >
                              <ModalHeader
                                toggle={toggleManageDistributor}
                                tag="h4"
                              >
                                Manage Distributor
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>Distributor</label>
                                        <ReactSelect
                                          users={[
                                            {
                                              id: "null",
                                              user_name: "None",
                                              email: "",
                                              phone: "",
                                            },
                                            ...distributor,
                                          ]}
                                          setSelectedOption={
                                            handleSelectedDistributor
                                          }
                                          selectedOption={selectedDistributor}
                                        />
                                      </div>
                                    </Col>
                                    {addendumData.length ? (
                                      <div className="mb-3">
                                        <label>Addendum No.</label>
                                        <ReactSelect
                                          users={addendumData}
                                          setSelectedOption={
                                            setSelectedAddendumData
                                          }
                                          selectedOption={selectedAddendumData}
                                          multiOptionLabel={true}
                                          optionLabelKeys={["addendum_no"]}
                                        />
                                      </div>
                                    ) : null}
                                    {selectedAddendumData ? (
                                      <div className="row">
                                        <div className="col col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="upfrontFeePercent"
                                              label="Upfront Fee Percent"
                                              type="number"
                                              min={0}
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              disabled={true}
                                              value={
                                                selectedAddendumData.upfront_fee_percent ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData.upfront_fee_percent
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="additionalFeePercent"
                                              label="Trail Fee Percent"
                                              type="number"
                                              errorMessage="Invalid Value"
                                              min={0}
                                              value={
                                                selectedAddendumData.additional_fee_percent ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData.additional_fee_percent
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="additionalFeePaymentFrequency"
                                              label="Trail Fee Payment Frequency"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              value={
                                                selectedAddendumData.additional_fee_payment_frequency ||
                                                ""
                                              }
                                              disabled={true}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="NOT_APPLICABLE">
                                                Not Applicable
                                              </option>
                                              <option value="MONTHLY">
                                                Monthly
                                              </option>
                                              <option value="QUARTERLY">
                                                Quarterly
                                              </option>
                                              <option value="HALF_YEARLY">
                                                Half Yearly
                                              </option>
                                              <option value="ANNUALLY">
                                                Yearly
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="trail_fee_applicable_days"
                                              label="Trail Fee Start Days"
                                              type="number"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                selectedAddendumData?.trail_fee_applicable_days ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData?.trail_fee_applicable_days
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={updateAddendumModal}
                              toggle={toggleUpdateAddendum}
                            >
                              <ModalHeader
                                toggle={toggleUpdateAddendum}
                                tag="h4"
                              >
                                Update Addendum
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>Distributor</label>
                                        <ReactSelect
                                          users={[
                                            {
                                              id: "null",
                                              user_name: "None",
                                              email: "",
                                              phone: "",
                                            },
                                            ...distributor,
                                          ]}
                                          setSelectedOption={
                                            handleSelectedDistributor
                                          }
                                          isDisabled={true}
                                          selectedOption={selectedDistributor}
                                        />
                                      </div>
                                    </Col>
                                    {addendumData.length ? (
                                      <div className="mb-3">
                                        <label>Addendum No.</label>
                                        <ReactSelect
                                          users={addendumData}
                                          setSelectedOption={
                                            setSelectedAddendumData
                                          }
                                          selectedOption={selectedAddendumData}
                                          multiOptionLabel={true}
                                          optionLabelKeys={["addendum_no"]}
                                        />
                                      </div>
                                    ) : null}
                                    {selectedAddendumData ? (
                                      <div className="row">
                                        <div className="col col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="upfrontFeePercent"
                                              label="Upfront Fee Percent"
                                              type="number"
                                              min={0}
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              disabled={true}
                                              value={
                                                selectedAddendumData.upfront_fee_percent ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData.upfront_fee_percent
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="additionalFeePercent"
                                              label="Trail Fee Percent"
                                              type="number"
                                              errorMessage="Invalid Value"
                                              min={0}
                                              value={
                                                selectedAddendumData.additional_fee_percent ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData.additional_fee_percent
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="additionalFeePaymentFrequency"
                                              label="Trail Fee Payment Frequency"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              value={
                                                selectedAddendumData.additional_fee_payment_frequency ||
                                                ""
                                              }
                                              disabled={true}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="NOT_APPLICABLE">
                                                Not Applicable
                                              </option>
                                              <option value="MONTHLY">
                                                Monthly
                                              </option>
                                              <option value="QUARTERLY">
                                                Quarterly
                                              </option>
                                              <option value="HALF_YEARLY">
                                                Half Yearly
                                              </option>
                                              <option value="ANNUALLY">
                                                Yearly
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="col col-md-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="trail_fee_applicable_days"
                                              label="Trail Fee Start Days"
                                              type="number"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                selectedAddendumData?.trail_fee_applicable_days ===
                                                0
                                                  ? "0"
                                                  : selectedAddendumData?.trail_fee_applicable_days
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={!addendumData.length}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            {Array.isArray(errorData) && errorData?.length ? (
                              <Modal
                                isOpen={errorModal}
                                toggle={() => setErrorModal(false)}
                              >
                                <ModalHeader
                                  toggle={() => setErrorModal(false)}
                                  tag="h4"
                                >
                                  Error Listing
                                </ModalHeader>
                                <ModalBody>
                                  {Array.isArray(errorData) &&
                                    errorData?.length &&
                                    errorData?.map((item, index) => {
                                      return (
                                        <div className="mb-3" key={index}>
                                          <ul>
                                            {errorMsg
                                              ? errorMsg
                                              : "Something Went Wrong"}
                                            <li>
                                              Pan Number :- {item?.pan_num}
                                            </li>
                                            <li>
                                              User Investment Id :-
                                              {item?.userInvestmentId}
                                            </li>
                                            <li>
                                              Payment Type :-{" "}
                                              {item?.payment_type}
                                            </li>
                                            <li>
                                              Transaction Amount:-{" "}
                                              {item?.transaction_amount}
                                            </li>
                                            <li>
                                              {" "}
                                              Transaction Ref Id :-{" "}
                                              {item?.txn_ref_id}
                                            </li>
                                          </ul>
                                        </div>
                                      )
                                    })}
                                </ModalBody>
                              </Modal>
                            ) : null}
                            <Modal
                              isOpen={uploadModal}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Top-Ups
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <>
                                        <div className="mt-2 mb-3">
                                          {loading && <Loader />}
                                          <AvField
                                            name="formFile"
                                            label="Upload Top-Ups"
                                            className="form-control"
                                            type="file"
                                            errorMessage="This field is required"
                                            accept=".csv"
                                            id="formFile"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={onSelectFile}
                                          />
                                        </div>
                                      </>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={downloadErrorModal}
                              toggle={toggleDownloadErrorModal}
                            >
                              <ModalHeader
                                toggle={toggleDownloadErrorModal}
                                tag="h4"
                              >
                                Invalid Request!
                              </ModalHeader>
                              <ModalBody>
                                <Row form>
                                  Download requested for more than 500 entries.
                                  Please select a filter before proceeding.
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-success save-user"
                                        onClick={toggleDownloadErrorModal}
                                      >
                                        OK
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={pdfModal} toggle={togglePdfModal}>
                              <ModalHeader toggle={togglePdfModal} tag="h4">
                                Generate PDF
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>PDF Type</label>
                                        <ReactSelect
                                          users={pdfTemplates}
                                          setSelectedOption={e =>
                                            setSelectedPdfTemplate(e)
                                          }
                                          multiOptionLabel={true}
                                          optionLabelKeys={["statusText"]}
                                        />
                                      </div>
                                      {selectedPdfTemplate &&
                                      selectedPdfTemplate.id == "STF" ? (
                                        <div className="row">
                                          {/* <div className="mb-3 col-md-6">
                                            <AvField
                                              name="serial_no"
                                              label="Serial Number"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div> */}
                                          <div className="mb-3 col">
                                            <AvField
                                              name="transferee_designation"
                                              label="Transferee Designation"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="execution_date"
                                              label="Execution Date"
                                              type="date"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              defaultValue={moment(
                                                finalSelectedRows[0].created_at
                                              ).format("YYYY-MM-DD")}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="settlement_days"
                                              label="Settlement Days"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              defaultValue={5}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="trade_date"
                                              label="Trade Date"
                                              type="date"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              defaultValue={moment(
                                                finalSelectedRows[0].created_at
                                              ).format("YYYY-MM-DD")}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <label>
                                              Select Debenture Trustee
                                            </label>
                                            <ReactSelect
                                              users={debentureTrusteeList}
                                              setSelectedOption={e =>
                                                setSelectedDebentureTrustee(e)
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "debenture_trustee_name",
                                                "debenture_trustee_cin",
                                              ]}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label>Select Transferor</label>
                                            <ReactSelect
                                              users={transferorMasterList}
                                              setSelectedOption={e =>
                                                setSelectedTransferorMaster(e)
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "transferor_name",
                                                "transferor_depository_name",
                                                "dp_name",
                                              ]}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Download PDF
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

userInvestmentTopUp.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(userInvestmentTopUp)
