import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData } from "constants/common"
import { AccessId, benPosReportTypes } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"

const DematBeneficiaryReport = () => {
  // Loader State
  const [loading, setLoading] = useState(false)

  // Master Data State
  const [tableData, setTableData] = useState([])

  // SSP State
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  // Other States
  const [userFilter, setUserFilter] = useState("")

  // File Constants
  const accessRestriction = DealManagementAccess(AccessId?.KYC)
  const dollarIndianLocale = Intl.NumberFormat("en-IN")

  // Constant Functions
  const handleAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }
  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  // Table Columns
  const TableColumns = download => {
    const columns = [
      { dataField: "user_name", text: "Investor Name", sort: true },
      { dataField: "user_pan.name", text: "Name on Pan", sort: true },
      { dataField: "email", text: "Investor Email", sort: true },
      { dataField: "user_pan.pan_number", text: "Investor Pan", sort: true },
      { dataField: "user_demat.demat_id", text: "Investor Demat", sort: true },
    ]
    tableData[0]?.beneficiary_demat_list?.forEach((el, index) =>
      columns.push({
        dataField: download
          ? el.transferor_code
          : `beneficiary_demat_list[${index}].status`,
        text: el.transferor_code,
        sort: true,
      })
    )
    columns.push({ dataField: "phone", text: "Investor Phone", sort: true })
    return columns
  }

  // Main Function - Fetch Table Data
  const masterData = async () => {
    setTableData([])
    setLoading(true)

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}`

    const getUrl = `/demat-beneficiary-documents-report?$limit=${limit}&$skip=${skip}${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  // Download Table data in Excel
  const downloadExcel = async (id, lots, date) => {
    try {
      setLoading(true)
      const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}`

      const downloadUrl = `/download-excel?type=benpos-report&$sort[created_at]=-1${filterUrl}`

      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  // Download Table data in CSV
  const downloadData = async fileName => {
    try {
      setLoading(true)
      const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}`

      const getUrl = `/demat-beneficiary-documents-report?${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = TableColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        console.log({ csvTableHeaders })
        console.log({ downloadableArr })
        JSONToCSVConvertor(downloadableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  // SSP Filters
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }
  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  // Pagination handler - Start
  useEffect(async () => {
    masterData()
  }, [limit, skip])
  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Demat Beneficiary Report" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction == 4 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={() =>
                        downloadData("Demat Beneficiary Documents")
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={TableColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative mb-3">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* Table and Modal start */}
                        <Row>
                          <Col xl="12">
                            {tableData.length ? (
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={TableColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            ) : null}
                          </Col>
                        </Row>
                        {/* Table and Modal End */}
                        {/* SSP Pagination Start */}
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                 `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* SSP Pagination End */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DematBeneficiaryReport.propTypes = {
  tableData: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DematBeneficiaryReport)
